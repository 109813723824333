import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { SizeMe } from "react-sizeme";
import { withTranslation } from "react-i18next";

class MerchantSticker extends Component {
  state = { numPages: null, pageNumber: 1, nextBtn: false, prevBtn: false };
  onDocumentLoadSuccess = ({ numPages }) => {
    let nextBtn = false;
    let prevBtn = false;
    if (numPages > 1) {
      nextBtn = true;
    }
    this.setState({ numPages, nextBtn, prevBtn });
  };

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div>
        <SizeMe
          monitorHeight
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div>
              <Document
                file="/merchant-poster.pdf"
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} width={size.width} />
              </Document>
            </div>
          )}
        />
      </div>
    );
  }
}
const MerchantStickerWithLang = withTranslation()(MerchantSticker);
export default MerchantStickerWithLang;
