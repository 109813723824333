import React from "react";
import Joi from "joi-browser";
//import Joi from "joi";
import Form from "./common/form";
import { Row, Col, Container, Image } from "react-bootstrap";
import LoginRegisterBtn from "./loginRegisterBtn";
import Signup from "../assets/signup.png";
import { Link } from "react-router-dom";
import "./common/common.css";
import { getKey, getPublicKey } from "../services/authService";
import {
  signUp,
  isValidJulAccName,
  isInvitedEmail,
} from "../services/userService";
import { toast } from "react-toastify";
import MyModal from "./common/myModal";
import queryString from "query-string";
import { getNameList } from "country-list";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import NavigationBarSignUpWithLang from "./navigationBarSignup";
class SignUpForm extends Form {
  state = {
    data: {
      accType: "1",
      julAccName: "",
      email: "",
      password: "",
      confirmPassword: "",
      referrer: "",
      genderId: "",
      yob: "",
      country: "",
      merchantName: "",
      businessOwnerName: "",
      agree: "",
    },
    countryNameList: [],
    yearOfBirthList: [],
    errors: {},
    successDialogOpen: false,
    loading: false,
    selected: [],
  };

  userSchema = {
    accType: Joi.number().integer().required(),
    julAccName: Joi.string()
      .required()
      .label("Username")
      .regex(/^([a-z1-5]){10}$/)
      .error(() => {
        return {
          message: this.props.t("only-a-z"),
        };
      }),
    email: Joi.string().email().required().label(this.props.t("only-a-z")),
    password: Joi.string().required().min(6).label(this.props.t("password")),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref("password"))
      .label(this.props.t("confirm-password")),
    referrer: Joi.string().allow("").label(this.props.t("referrer-email-id")),
    genderId: Joi.string().required().label(this.props.t("gender")),
    yob: Joi.number().integer().required().label(this.props.t("year-of-birth")),
    country: Joi.string().required().label(this.props.t("country")),
    agree: Joi.boolean()
      .required()
      .label("Agree")
      .error(() => {
        return {
          message: this.props.t("you-need-to-agree"),
        };
      }),
  };

  merchantSchema = {
    accType: Joi.number().integer().required(),
    julAccName: Joi.string()
      .required()
      .label("Username")
      .regex(/^([a-z1-5]){10}$/)
      .error(() => {
        return {
          message: this.props.t("only-a-z"),
        };
      }),
    email: Joi.string().email().required(),
    password: Joi.string().required().min(6).label(this.props.t("password")),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref("password"))
      .label(this.props.t("confirm-password")),
    referrer: Joi.string().allow("").label(this.props.t("referrer-email-id")),
    country: Joi.string().required().label(this.props.t("country")),
    merchantName: Joi.string()
      .max(50)
      .required()
      .label(this.props.t("merchant-name")),
    businessOwnerName: Joi.string()
      .max(50)
      .required()
      .label(this.props.t("business-owner-name")),
    // merchantName: Joi.string().required().label(this.props.t("merchant-name")),
    // businessOwnerName: Joi.string().required().label(this.props.t("business-owner-name")),

    // merchantName: Joi.string().label(this.props.t("merchant-name")),
    // businessOwnerName: Joi.string().label(this.props.t("business-owner-name")),
    agree: Joi.boolean()
      .required()
      .label("Agree")
      .error(() => {
        return {
          message: this.props.t("you-need-to-agree"),
        };
      }),
  };

  schema = this.userSchema;

  doHandleChange = (input, errorMessage) => {
    if (input.name === "accType") {
      this.schema = input.value === "1" ? this.userSchema : this.merchantSchema;
      console.log("schema", this.schema)
    } else if (input.name === "confirmPassword") {
      const password = this.schema["password"];
      const confirmPassword = this.schema["confirmPassword"];
      const { error } = Joi.validate(
        {
          password: this.state.data.password,
          confirmPassword: input.value,
        },
        { password, confirmPassword }
      );
      if (error) {
        return this.props.t("password-confirm-password");
      } else {
        return null;
      }
    }
    return errorMessage;
  };

  doSubmit = async () => {
    // Call the server
    console.log("Submitted");
    const key = await getKey(
      this.state.data.julAccName,
      this.state.data.password
    );

    const publicKey = await getPublicKey(key);
    // get the public key from the private Key
    const accType = parseInt(this.state.data.accType);
    const user = {
      accType: parseInt(this.state.data.accType),
      email: this.state.data.email,
      julAccName: this.state.data.julAccName,
      publicKey: publicKey,
      country: this.state.data.country,
    };

    if (accType === 1) {
      user.gender = this.state.data.genderId;
      user.yob = this.state.data.yob;
    } else {
      console.log("get public key")
      user.merchantName = this.state.data.merchantName;
      user.businessOwnerName = this.state.data.businessOwnerName;
    }

    try {
      const result = await signUp(user);
      const successDialogOpen = true;
      this.setState({ successDialogOpen });
      console.log(result);
    } catch (ex) {
      if (ex.response) {
        toast.error(ex.response.data);
        console.log(ex.response);
      }
    }
  };

  doFocusOut = async (input) => {
    let errorMessage = null;
    // console.log(input);
    switch (input.name) {
      case "julAccName":
        try {
          await isValidJulAccName(input.value);
          errorMessage = this.props.t("account-name-already-exists");
        } catch (ex) {
          if (ex.response && ex.response.status === 404) {
            console.log(ex.response);
          }
        }
        break;
      case "email":
        try {
          const { data: user } = await isInvitedEmail(input.value);
          // update the the referrer
          console.log(user);
          const data = { ...this.state.data };
          data.referrer = user.referrer.email;
          this.setState({ data });
        } catch (ex) {
          if (ex.response) {
            console.log(ex.response);
            if (ex.response.data === "Email ID is not invited") {
              errorMessage = this.props.t("email-id-not-invited");
            } else {
              errorMessage = this.props.t("email-id-already-registered");
            }
          } else {
            errorMessage = this.props.t("email-id-not-invited");
          }
        }
        break;
      default:
    }

    return errorMessage;
  };

  handleSuccessDialogClose = () => {
    this.setState({ successDialogOpen: false });
    window.location = "/login";
  };

  componentDidMount() {
    const countryList = getNameList();
    let countryNameList = [];

    Object.keys(countryList).forEach((key) => {
      // if (!key.includes("united states")) {
      countryNameList.push({
        _id: countryList[key],
        name: key.toUpperCase(),
      });
      // }
    });
    const currentYear = new Date().getFullYear();

    let yearOfBirthList = [];
    _.range(currentYear - 18, currentYear - 18 - 100).forEach((value) => {
      yearOfBirthList.push({ _id: value, name: value });
    });
    this.setState({ countryNameList, yearOfBirthList });
    const params = queryString.parse(this.props.location.search);
    const data = { ...this.state.data };

    if ("email" in params) {
      console.log(params.email);
      data.email = params.email;
      this.setState({ data });
    }

    if ("referrer" in params) {
      console.log(params.referrer);
      data.referrer = params.referrer;
      this.setState({ data });
    }
  }

  handleSubmitOveride = (e) => {
    e.preventDefault();
    // depending upon the account type remove the elements this is done only for the validations
    if (this.state.data.accType === "1") {
      delete this.state.data.businessOwnerName;
      delete this.state.data.merchantName;
      console.log("test")
    } else {
      delete this.state.data.genderId;
      delete this.state.data.yob;
    }
    const errors = this.validate();

    if (this.state.data.accType === "1") {
      this.state.data.businessOwnerName = "";
      this.state.data.merchantName = "";
    } else {
      this.state.data.genderId = "";
      this.state.data.yob = "";
    }
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  render() {
    const { t } = this.props;
    let userAccClass =
      this.state.data.accType === "1"
        ? "btn btn-success  active"
        : "btn btn-success ";

    let merAccClass =
      this.state.data.accType === "2"
        ? "btn btn-success  active"
        : "btn btn-success ";
    return (
      <React.Fragment>
        <MyModal
          heading={t("sign-up")}
          body={t("registration-successful")}
          show={this.state.successDialogOpen}
          onHide={this.handleSuccessDialogClose}
        />
        <NavigationBarSignUpWithLang />
        <Container fluid className="login-bg signup">
          <Row className="h-100">
            <Col xs={12} sm={8} md={7} lg={7} className="mx-auto my-2 my-md-5">
              <div className="login my-3 my-lg-0">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={7} className="pr-lg-0">
                    <div className="sign-in p-3 p-lg-4">
                      <Image src={Signup} className="mx-auto d-block mb-2" />
                      {/* <h3 className="text-center mt-2 mb-3 ">{t("sign-up")}</h3> */}
                      <form onSubmit={this.handleSubmitOveride}>
                        <Row className="sign-btn-top mb-4">
                          <Col className="pr-lg-0">
                            {" "}
                            <button
                              type="button"
                              className={userAccClass}
                              onClick={() => {
                                const data = { ...this.state.data };
                                data.accType = "1";
                                this.schema = this.userSchema
                                this.setState({ data });
                              }}
                            >
                              {t("sign-up-as-user")}
                            </button>
                          </Col>
                          <Col>
                            <button
                              type="button"
                              className={merAccClass}
                              onClick={() => {
                                const data = { ...this.state.data };
                                data.accType = "2";
                                this.schema = this.merchantSchema
                                this.setState({ data });
                              }}
                            >
                              {t("sign-up-as-merchant")}
                            </button>
                          </Col>
                        </Row>
                        {this.renderInput("email", t("email-id"), "text",
                          false,
                          { textTransform: "lowercase" })}
                        {this.renderInput(
                          "referrer",
                          t("referrer-email-id"),
                          "text",
                          true
                        )}
                        {this.renderInput(
                          "julAccName",
                          t("joule-account-name"),
                          "text",
                          false,
                          { textTransform: "lowercase" }
                        )}


                        {this.renderInput(
                          "password",
                          t("password"),
                          "password"
                        )}
                        <p className="password-warn mt-3 d-block">
                          {t("password-warning")}
                        </p>

                        {this.renderInput(
                          "confirmPassword",
                          t("confirm-password"),
                          "password"
                        )}
                        {this.state.data.accType === "1" &&
                          this.renderSelect("genderId", t("gender"), [
                            { _id: "male", name: t("male") },
                            { _id: "female", name: t("female") },
                          ])}
                        {/* {this.renderSelect("yob", "Year of Birth")} */}
                        {this.state.data.accType === "1" &&
                          this.renderSelect(
                            "yob",
                            t("year-of-birth"),
                            this.state.yearOfBirthList
                          )}
                        {this.renderSelect(
                          "country",
                          t("country"),
                          this.state.countryNameList
                        )}
                        {this.state.data.accType === "2" &&
                          this.renderInput("merchantName", t("merchantName"))}
                        {this.state.data.accType === "2" &&
                          this.renderInput(
                            "businessOwnerName",
                            t("businessOwnerName"), "text"
                          )}
                        {this.renderCheckBox("agree")}
                        {this.renderButton(t("sign-up"))}
                      </form>
                      <h6 className="text-center mt-3 d-block d-sm-block d-md-block d-md-none d-lg-none">
                        {t("already-have-an-account")}{" "}
                        <Link to="/login">{t("sign-in-here")}</Link>
                      </h6>
                    </div>
                  </Col>
                  <Col
                    md={5}
                    sm={5}
                    className="pl-0 m-auto d-none d-sm-none d-md-none d-lg-block"
                  >
                    <LoginRegisterBtn isSignIn={false} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const SignUpFormWithLang = withTranslation()(SignUpForm);
export default SignUpFormWithLang;
