import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import "./common.css";
import { Spinner } from "react-bootstrap";


const buildUsefulErrorObject = (errors) => {
  const usefulErrors = {};
  errors.forEach((error) => {
    if (!usefulErrors.hasOwnProperty(error.path.join("_"))) {
      usefulErrors[error.path.join("_")] = {
        type: error.type,
        msg: `error.${error.path.join("_")}.${error.type}`,
      };
    }
  });
  return usefulErrors;
};

class Form extends Component {
  state = {
    data: {},
    errors: {},
    loading: false,
  };

  validate = () => {
    const options = { abortEarly: false };


    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    const result = buildUsefulErrorObject(error.details);
    for (let item of error.details)
      errors[item.path[0]] = result[item.path[0]].msg;
    return errors;
  };

  validateProperty = (input) => {
    const { name, value } = input;
    const obj =
      input.type !== "checkbox"
        ? { [name]: value }
        : { [name]: input.checked ? true : "" };
    const schema = { [name]: this.schema[name] };
    console.log("test", value)

    const { error } = Joi.validate(obj, schema);


    return error ? buildUsefulErrorObject(error.details)[name].msg : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    console.log("onchange");
    if (input.style.textTransform === "lowercase") {
      input.value = input.value.toLowerCase();
    }
    const errors = { ...this.state.errors };
    let errorMessage = this.validateProperty(input);
    console.log("error", errorMessage)
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    if (input.type === "checkbox") {
      data[input.name] = input.checked === true ? true : "";
    } else {
      data[input.name] = input.value;
    }

    // added for confirm password handling
    if (typeof this.doHandleChange !== "undefined") {
      const newErrorMessage = this.doHandleChange(input, errorMessage);
      if (newErrorMessage) {
        if (newErrorMessage !== errorMessage) {
          errors[input.name] = newErrorMessage;
        }
      } else {
        delete errors[input.name];
      }
    }

    this.setState({ data, errors });
  };

  handleFocusOut = async ({ currentTarget: input }) => {
    console.log("focus")

    let errorMessage = this.validateProperty(input);

    if (!errorMessage) {
      if (typeof this.doFocusOut !== "undefined") {
        const errors = { ...this.state.erros };
        errorMessage = await this.doFocusOut(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        this.setState({ errors });
      }
    }
  };

  renderButton = (label) => {
    // return <button className="btn btn-success mx-auto d-block">{label}</button>;

    if (this.state.loading === false) {
      return (
        <button className="btn btn-success mx-auto d-block">{label}</button>
      );
    } else {
      return (
        <button className="btn btn-success mx-auto d-block" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading..
        </button>
      );
    }
  };

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        options={options}
        onChange={this.handleChange}
        error={this.props.t(errors[name])}
      />
    );
  }

  renderInput(name, label, type = "text", readOnly = false, style = {}) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        readOnly={readOnly}
        error={errors[name]}
        onBlur={this.handleFocusOut}
        style={style}
      />
    );
  }

  renderSimpleInput(name, label, type = "text", style = {}) {
    const { data, errors } = this.state;
    const error = errors[name];
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          value={data[name]}
          type={type}
          name={name}
          id={name}
          onChange={this.handleChange}
          className="form-control"
          style={style}
        />
        {/* <label htmlFor={name}>{label}</label> */}

        {error && (
          <div className="alert alert-primary">{this.props.t(error)}</div>
        )}
      </div>
    );
  }

  renderCheckBox(name) {
    const { t } = this.props;
    const { data, errors } = this.state;
    const error = errors[name];
    return (
      <div>
        <label htmlFor={name} className="agree-label">
          <input
            id={name}
            type="checkbox"
            value={data[name]}
            onChange={this.handleChange}
            name={name}
          />
          &nbsp; {t("agree-with-the")}
          <a href="https://www.jouleindex.com/terms-and-conditions.html">
            {t("terms-of-service")}
          </a>{" "}
          {t("understanding-fake")}
        </label>
        {error && (
          <div className="alert alert-primary">{this.props.t(error)}</div>
        )}
      </div>
    );
  }
}
export default Form;
