import React from "react";
import { Button } from "react-bootstrap";
import "./common/common.css";
import { Link } from "react-router-dom";
import Joulelogo from "../assets/joule-logo.png";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

export default function LoginRegisterBtn({ isSignIn }) {
  const { t } = useTranslation();
  return (
    <div className="login-right">
      <Image src={Joulelogo} className="mx-auto" />
      <h5 className="mt-1 px-3 ">{t("blockchain-revolution-wallet")}</h5>
      <h5 className="mt-1 px-3">{t("appTag")}</h5>
      <p className="px-3">{t("trade-on-popularity")}</p>

      {isSignIn ? (
        <Link to="/register">
          <Button className="btn btn-primary text-uppercase">
            {t("sign-up")}
          </Button>
        </Link>
      ) : (
        <Link to="/login">
          <Button className="btn btn-primary text-uppercase">
            {t("sign-in")}
          </Button>
        </Link>
      )}

      {/* <Link to="/login">{"Don't have an account? Sign Up"}</Link> */}

      {/* <Button><Link to="/register">Register</Link></Button> */}
    </div>
  );
}
