import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { Row, Col, Container, Image } from "react-bootstrap";
import LoginRegisterBtn from "./loginRegisterBtn";
import "./common/common.css";
import Login from "../assets/login.png";
import { Link } from "react-router-dom";
import { getJulAccName, createJulAccount } from "../services/userService";
import {
  login,
  getCurrentUser,
  updateAccCreatedStatus,
  getWithdrawalKey,
  getPublicKey,
} from "../services/authService";
import { setPubKey } from "../services/dappClientService";
import queryString from "query-string";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import NavigationBarSignUpWithLang from "./navigationBarSignup";
import PinInput from "react-pin-input";

function is_valid_account_name(account_name) {
  let re = new RegExp("^([a-z1-5]){10}$");
  return re.test(account_name);
}

class SignInForm extends Form {
  state = {
    data: { accNameEmail: "", password: "" },
    errors: {},
    passcode: "",
    errorPasscode: "",
    loading: false,
    isAccCreated: false,
  };

  schema = {
    accNameEmail: Joi.string().label(
      this.props.t("email-or-joule-account-name")
    ),
    password: Joi.string().required().min(6).label("Password"),
  };

  set4DigitPin = async () => {
    const user = getCurrentUser();
    let key = getWithdrawalKey(this.state.passcode, user.key);
    let publicKey = getPublicKey(key);

    const result = await setPubKey(user.julAccName, publicKey, user.key);
    // if (result.success) {
    //   toast.success(this.props.t(result.msg));
    // } else {
    //   toast.error(this.props.t(result.msg));
    // }
  };

  checkAndCreateAccount = async () => {
    const user = getCurrentUser();
    if (user) {
      if ("isAccCreated" in user && user["isAccCreated"] === true) {
        console.log("redirection to home page");
        await this.set4DigitPin();
        window.location = "/";
      } else {
        console.log("show create account");
        // create joule account
        try {
          const { data } = await createJulAccount();
          //update the user details
          updateAccCreatedStatus();
          await this.set4DigitPin();
          window.location = "/";
          console.log(data);
        } catch (ex) {
          if (ex.response) {
            console.log(ex.response.data);
            toast.error(ex.response.data);
          }
        }
      }
    }
  };

  doSubmit = async () => {
    const { t } = this.props;

    if (this.state.passcode.length !== 4) {
      // passcode length is
      this.setState({ errorPasscode: t("4-digit-present") });
      return;
    }

    this.setState({ loading: true });
    //check if the entered value is user name or password
    const schema = Joi.string().email();
    let emailEntered = false;
    let isValidEmailOrAccName = true;
    let julAccName;
    try {
      await schema.validate(this.state.data.accNameEmail);
      emailEntered = true;
    } catch (ex) { }
    if (emailEntered) {
      try {
        // get jul account name from email ID
        const { data } = await getJulAccName(this.state.data.accNameEmail);
        julAccName = data.julAccName;
        console.log(data);
      } catch (ex) {
        isValidEmailOrAccName = false;
      }
    } else {
      if (!is_valid_account_name(this.state.data.accNameEmail)) {
        isValidEmailOrAccName = false;
      } else {
        julAccName = this.state.data.accNameEmail;
      }
    }
    const errors = { ...this.state.errors };
    if (!isValidEmailOrAccName) {
      errors["accNameEmail"] = t("invalid-email-joule-account-name");
      this.setState({ errors });
      this.setState({ loading: false });
      return;
    }
    // generate the key and click the authenticaiton link
    try {
      await login(julAccName, this.state.data.password);
      await this.checkAndCreateAccount();
    } catch (ex) {
      if (ex.response) {
        console.log(ex.response.data);
        if (ex.response.data === "Authentication failure.") {
          errors["accNameEmail"] = t("authentication-failure");
        } else {
          errors["accNameEmail"] = ex.response.data;
        }

        this.setState({ errors });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if ("email" in params) {
      console.log(params.email);
      const data = { ...this.state.data };
      data.accNameEmail = params.email;
      this.setState({ data });
    }
    await this.checkAndCreateAccount();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <NavigationBarSignUpWithLang />
        <Container fluid className="login-bg">
          <Row className="h-100">
            <Col xs={12} sm={8} md={7} lg={7} className="m-auto my-2 my-md-5">
              <div className="login">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={7} className="pr-lg-0">
                    <div className="sign-in p-3 p-lg-4">
                      <Image src={Login} className="mx-auto d-block" />
                      <h3 className="text-center mt-2 mb-3 ">{t("sign-in")}</h3>
                      <form onSubmit={this.handleSubmit}>
                        {this.renderInput(
                          "accNameEmail",
                          t("email-or-joule-account-name"),
                          "text",
                          false,
                          { textTransform: "lowercase" }
                        )}

                        {this.renderInput(
                          "password",
                          t("password"),
                          "password"
                        )}
                        <p className="password-warn mt-3 d-block">
                          {t("password-warning")}
                        </p>
                        <Row className="pl-3">
                          <label>{t("4-digit-reminder")}</label>
                        </Row>
                        <Row className="pl-3">
                          <PinInput
                            length={4}
                            initialValue=""
                            secret
                            onChange={(value, index) =>
                              this.setState({ passcode: value })
                            }
                            type="numeric"
                            inputMode="number"
                            style={{ padding: "10px" }}
                            inputStyle={{ borderColor: "#009688" }}
                            inputFocusStyle={{ borderColor: "#fff" }}
                            onComplete={(value, index) => { }}
                            autoSelect={true}
                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                          />
                          {this.state.errorPasscode && (
                            <div className="alert alert-primary">
                              {this.state.errorPasscode}
                            </div>
                          )}
                        </Row>
                        {this.renderButton(t("sign-in"))}
                      </form>
                      <h6 className="text-center mt-3 d-block d-sm-block d-md-block d-lg-none">
                        {t("no-account-yet")}{" "}
                        <Link to="/register">{t("sign-up-here")}</Link>
                      </h6>
                    </div>
                  </Col>
                  <Col
                    md={5}
                    className="pl-0 m-auto d-none d-sm-none d-md-none d-lg-block"
                  >
                    <LoginRegisterBtn isSignIn={true} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const SignInFormWithLang = withTranslation()(SignInForm);
export default SignInFormWithLang;
