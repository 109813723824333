import React, { useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  Row,
  Col,
  Table,
  Accordion,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import Upward from "../assets/upward.png";
import Downward from "../assets/downward.png";
import Price from "../assets/price-s.svg";
import Up from "../assets/arrow_up-s.svg";
import Down from "../assets/arrow_down-s.svg";
import Lowhigh from "../assets/day_low_high.svg";
import Image from "react-bootstrap/Image";
import { imgBaseUrl } from "../config.js";
import _ from "lodash";
import { toast } from "react-toastify";
import Piecharts from "./charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import {
  faAngleDown,
  faCircle,
  faThumbsUp,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faOBookmark } from "@fortawesome/free-regular-svg-icons";
import {
  getNews,
  getStatistics,
  getPosts,
  addPost,
  votePost,
  reportPost,
} from "../services/userService";
import { getName } from "country-list";
import CandleStickChart from "./candleStickChart";
import { useTranslation } from "react-i18next";

const numElementsInTable = 3;

export default function Item({
  item,
  onPlaceOrder,
  showWatchList,
  onWatchlistClicked,
  isFavorite,
  openOrders,
  onOpenOrderClicked,
  itemOpenOrderIndex,
  show,
  handleClose,
  handleShow,
}) {
  const imageUrl = `${imgBaseUrl}${item.id}.jpg`;

  const [key, setKey] = useState("");
  const [news, setNews] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [posts, setPosts] = useState([]);
  const [newposts, setNewPosts] = useState([]);
  const { t } = useTranslation();
  const [forum, setForum] = React.useState(false);
  const [postsDisplay, setPostsDisplay] = React.useState([]);
  const [viewMore, setViewMore] = useState(false);

  function convertCountryCodeToName(countryCodeList) {
    let countryNames = {};
    Object.entries(countryCodeList).forEach(([key, value]) => {
      let countryName = getName(key);
      if (countryName) {
        countryNames[countryName] = value;
      } else {
        // should never happen only when there is invalid country ID
        countryNames[key] = value;
      }
    });
    return countryNames;
  }

  const dayLowHigh = ` ${(item.day_low / 10).toFixed(1)} - ${(
    item.day_high / 10
  ).toFixed(1)}`;

  const marketPrice = ` ${(item.market_price / 10).toFixed(1)} J`;
  const diffPrice = item.market_price - item.prev_close_price;
  const diffPriceText = ` ${Math.abs((diffPrice / 10).toFixed(1))} J`;

  for (let i = 0; i < item.trade.length; i++) {
    item.trade[i].ownLongOrder = false;
    item.trade[i].ownShortOrder = false;
    item.trade[i].order = [];
    itemOpenOrderIndex.forEach((index) => {
      if (
        item.trade[i].price ===
        parseInt(parseFloat(openOrders[index].order_price) * 10)
      ) {
        if (openOrders[index].pos_type === "Long") {
          item.trade[i].ownLongOrder = true;
        } else {
          item.trade[i].ownShortOrder = true;
        }
        item.trade[i].order.push(index);
      }
    });
  }

  const [post, setPost] = useState({
    title: "",
    content: "",
    postType: "",
    itemId: item.id,
  });

  function handleInputChange(e) {
    let input = post;
    input[e.target.id] = e.target.value;
    // console.log(input);
    setPost(input);
  }

  function handleViewMore() {
    if (postsDisplay.length + numElementsInTable < posts.length) {
      setPostsDisplay(posts.slice(0, postsDisplay.length + numElementsInTable));
      setViewMore(true);
    } else {
      setPostsDisplay(posts);
      setViewMore(false);
    }
  }

  return (
    <React.Fragment>
      <Col md={6} sm={6} lg={4} className="mb-3">
        <div className="alltab mb-0">
          <div className="image-bann">
            <Image src={imageUrl} className="img-fluid" />
            <h4>{item.name}</h4>
            {showWatchList && (
              <FontAwesomeIcon
                icon={isFavorite ? faBookmark : faOBookmark}
                size="3x"
                color="#009688"
                className="pt-3 favourite"
                onClick={() => {
                  onWatchlistClicked(item.id);
                }}
              />
            )}
            <Container className="item-price">
              <Row>
                <Col lg={4} xs={4} sm={4} className="pr-0">
                  <Image src={Price} height={25} />
                  {marketPrice}
                </Col>
                <Col lg={3} xs={3} sm={3} className="px-0 text-center">
                  {diffPrice >= 0 ? (
                    <Image src={Up} height={25} />
                  ) : (
                    <Image src={Down} height={25} />
                  )}
                  {diffPriceText}
                </Col>
                <Col lg={5} xs={5} sm={5} className="text-right pl-0">
                  <Image src={Lowhigh} height={25} />
                  {dayLowHigh}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="tab-feature">
            <Tabs
              defaultActiveKey=""
              transition={false}
              id="item tabs"
              activeKey={key}
              onSelect={async (k) => {
                if (k !== key) {
                  if (k === "news") {
                    try {
                      const { data } = await getNews(item.id);
                      setNews(data);
                    } catch (ex) {
                      setNews([]);
                    }
                  } else if (k === "statistics") {
                    try {
                      let { data } = await getStatistics(item.id);
                      if (data && data.long && data.long.country) {
                        data.long.country = convertCountryCodeToName(
                          data.long.country
                        );
                      }
                      if (data && data.short && data.short.country) {
                        data.short.country = convertCountryCodeToName(
                          data.short.country
                        );
                      }
                      setStatistics(data);
                    } catch (ex) {
                      setStatistics({});
                    }
                  } else if (k === "forum") {
                    try {
                      let { data } = await getPosts(item.id);
                      setPosts(data);
                      if (data.length > numElementsInTable) {
                        setPostsDisplay(data.slice(0, numElementsInTable));
                        setViewMore(true);
                      } else {
                        setPostsDisplay(data);
                        setViewMore(false);
                      }
                      // console.log(data);
                    } catch (ex) {
                      setPosts([]);
                    }
                  }
                  setKey(k);
                } else {
                  setKey("");
                }
              }}
            >
              <Tab eventKey="news" title={t("news")}>
                {news.map((n) => (
                  <div className="news-area px-2 pt-3 pb-2" key={n._id}>
                    <Row>
                      <Col xs={4} md={5}>
                        <Image src={n.urlToImage} className="img-fluid" />
                      </Col>
                      <Col xs={8} md={7} className="pl-0 my-auto">
                        {" "}
                        <h5 className="text-left">
                          <a style={{ display: "table-cell" }} href={n.url}>
                            {n.title}
                          </a>
                        </h5>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Tab>
              <Tab eventKey="statistics" title={t("statistics")}>
                {!_.isEmpty(statistics) ? (
                  <Row className="py-2">
                    <Col md={12} className="stats-tab">
                      <Tabs
                        defaultActiveKey="age"
                        id="uncontrolled-tab-example"
                        className="mx-auto"
                      >
                        <Tab eventKey="age" title={t("age")} className="mt-4">
                          <Row>
                            <Col md={12}>
                              <h5 className="text-center">{t("long")}</h5>
                              <Piecharts data={statistics.long.age} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <h5 className="text-center">{t("short")}</h5>
                              <Piecharts data={statistics.short.age} />
                            </Col>
                          </Row>
                        </Tab>

                        <Tab
                          eventKey="gender"
                          title={t("gender")}
                          className="mt-4"
                        >
                          <Row>
                            <Col md={12}>
                              <h5 className="text-center">{t("long")}</h5>
                              <Piecharts data={statistics.long.gender} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <h5 className="text-center">{t("short")}</h5>
                              <Piecharts data={statistics.short.gender} />
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="country"
                          title={t("country")}
                          className="mt-4"
                        >
                          <Row>
                            <Col md={12}>
                              <h5 className="text-center">{t("long")}</h5>
                              <Piecharts data={statistics.long.country} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <h5 className="text-center">{t("short")}</h5>
                              <Piecharts data={statistics.short.country} />
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                ) : (
                  <h5 className="text-center py-4">{t("not-available")}</h5>
                )}
              </Tab>
              <Tab eventKey="trade" title={t("trade")}>
                <Table bordered className="mb-0">
                  <thead>
                    <tr>
                      <th colSpan="3">{t("long-quantity")}</th>
                      <th>{t("price")}</th>
                      <th colSpan="3">{t("short-quantity")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {item.trade.map((trade, index) => (
                      <tr key={index}>
                        <td>
                          {trade.upArrowBtn && (
                            <Image
                              src={Upward}
                              onClick={() =>
                                onPlaceOrder({
                                  pos_type: 1,
                                  item_id: item.id,
                                  price: trade.price,
                                  item_name: item.name,
                                })
                              }
                            />
                          )}
                        </td>

                        <td>
                          {trade.ownLongOrder && (
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="2x"
                              color="#009688"
                              className="float-left pl-2 own-order"
                              onClick={() => {
                                onOpenOrderClicked(trade.order);
                              }}
                            />
                          )}
                        </td>

                        {trade.longQty !== 0 ? (
                          <td> {trade.longQty} </td>
                        ) : (
                          <td></td>
                        )}

                        {trade.isMarketPrice ? (
                          <td className="active-no">
                            {(trade.price / 10).toFixed(1)}
                          </td>
                        ) : (
                          <td>{(trade.price / 10).toFixed(1)}</td>
                        )}

                        {trade.shortQty !== 0 ? (
                          <td> {trade.shortQty} </td>
                        ) : (
                          <td></td>
                        )}
                        <td>
                          {trade.ownShortOrder && (
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="2x"
                              color="#e63d3a"
                              className="float-right pr-2 own-order"
                              onClick={() => {
                                onOpenOrderClicked(trade.order);
                              }}
                            />
                          )}
                        </td>

                        <td>
                          {trade.downArrowBtn && (
                            <Image
                              src={Downward}
                              onClick={() =>
                                onPlaceOrder({
                                  pos_type: -1,
                                  item_id: item.id,
                                  price: trade.price,
                                  item_name: item.name,
                                })
                              }
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Accordion defaultActiveKey="">
                  <Accordion.Toggle
                    style={{ width: "100%" }}
                    as={Card.Header}
                    variant="link"
                    eventKey="0"
                  >
                    <Row>
                      <Col xs={10} md={10}>
                        <FontAwesomeIcon
                          icon={faChartLine}
                          size="2x"
                          color="#009688"
                          className="float-left pr-2"
                        />
                        <h5
                          className="mt-2"
                          style={{ fontSize: "1em", color: "#ccc" }}
                        >
                          {t("priceChart")}
                        </h5>
                      </Col>
                      <Col xs={2} md={2}>
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          size="2x"
                          color="#009688"
                          className="text-right"
                        />
                      </Col>
                    </Row>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <CandleStickChart
                      item_id={item.id}
                      title={t("priceChart")}
                    ></CandleStickChart>
                  </Accordion.Collapse>
                </Accordion>
              </Tab>
              <Tab eventKey="forum" title={t("forum")}>
                <Container className="py-3">
                  <Modal
                    show={forum}
                    onHide={() => setForum(false)}
                    className="forum-post"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="mt-0">
                      <h4 className="mb-4">Create Post</h4>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            placeholder={t("post_title")}
                            id="title"
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control
                            as="textarea"
                            id="content"
                            rows={4}
                            placeholder={t("post_content")}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <h6 className="mb-4">{t("post_type")}</h6>
                        <Button
                          variant="dark"
                          className="mr-3"
                          onClick={() => {
                            let data = { ...post };
                            data.postType = "long";
                            setPost(data);
                          }}
                          active={post.postType === "long"}
                        >
                          <Image src={Up} />
                        </Button>{" "}
                        <Button
                          variant="dark"
                          onClick={() => {
                            let data = { ...post };
                            data.postType = "short";
                            setPost(data);
                          }}
                          active={post.postType === "short"}
                        >
                          <Image src={Down} />
                        </Button>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={async () => {
                          try {
                            await addPost(post);
                            setForum(false);
                            try {
                              let { data } = await getPosts(item.id);
                              // setPostsDisplay(data);

                              setPosts(data);
                              if (data.length > numElementsInTable) {
                                setPostsDisplay(data.slice(0, numElementsInTable));
                                setViewMore(true);
                              } else {
                                setPostsDisplay(data);
                                setViewMore(false);
                              }
                              console.log(data);
                            } catch (ex) {
                              setPostsDisplay([]);
                            }
                          } catch (ex) {
                            if (ex.response) {
                              toast.error(ex.response.data);
                            }
                          }
                        }}
                        className="mt-4"
                      >
                        {t("post")}
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <h4 className="post-add ml-auto">
                    <Button variant="dark" onClick={() => setForum(true)}>
                      {t("add_post")}
                    </Button>
                  </h4>
                  {postsDisplay.map((n) => (
                    <div className="forum-bg" key={n._id}>
                      <Row>
                        <Col md={2} className="pr-0">
                          {n.postType === "long" ? (
                            <Image src={Up} className="img-fluid w-50" />
                          ) : (
                            <Image src={Down} className="img-fluid w-50" />
                          )}
                        </Col>
                        <Col md={10} className="pl-0">
                          <h6 class="mb-1">{n.title}</h6>
                          <p class="mb-1">{n.content}</p>
                          <ul class="list-unstyled pl-0 mb-0">
                            <li>
                              <FontAwesomeIcon
                                onClick={async () => {
                                  try {
                                    await votePost(n._id);
                                    try {
                                      let { data } = await getPosts(item.id);
                                      if (data.length > numElementsInTable) {
                                        setPostsDisplay(data.slice(0, postsDisplay.length));
                                      } else {
                                        setPostsDisplay(data);
                                      }
                                      toast.success(t("vote_added"));
                                    } catch (ex) {
                                      setPostsDisplay([]);
                                    }
                                  } catch (ex) {
                                    toast.error(t("vote_fail"));
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                                icon={faThumbsUp}
                                size="1x"
                                color="#009688"
                              />
                              <span class="ml-1">{n.votes.length}</span>
                            </li>
                            <li>
                              <FontAwesomeIcon
                                onClick={async () => {
                                  try {
                                    await reportPost(n._id);
                                    try {
                                      let { data } = await getPosts(item.id);
                                      if (data.length > numElementsInTable) {
                                        setPostsDisplay(data.slice(0, postsDisplay.length));
                                      } else {
                                        setPostsDisplay(data);
                                      }
                                      toast.success(t("report_added"));
                                    } catch (ex) {
                                      setPostsDisplay([]);
                                    }
                                  } catch (ex) {
                                    toast.error(t("report_fail"));
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                                icon={faExclamationCircle}
                                size="1x"
                                color="#009688"
                              />
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {viewMore && (
                    <Button
                      variant="default"
                      className="mx-auto d-block"
                      onClick={handleViewMore}
                    >
                      View More
                    </Button>
                  )}
                </Container>
              </Tab>
            </Tabs>
          </div>
        </div>
        {/* </Animated> */}
      </Col>
    </React.Fragment>
  );
}
