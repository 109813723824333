
import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Image,
  Tab,
  Spinner,
  Tabs,
  Form,
  Dropdown,
} from "react-bootstrap";
import Balance from "../assets/balance.png";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import ShareForm from "./shareForm";
import PayForm from "./payForm";
import Cancel from "../assets/cancel.png";
import JouleForm from "./jouleForm";
import ChangePwForm from "./changePwForm";
import QRCode from "qrcode.react";
import InviteForm from "./inviteForm";
import Receive from "../assets/receive.svg";
import Share from "../assets/share.svg";
import Pay from "../assets/pay.svg";
import Buy from "../assets/buy.svg";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Faq from "./faq";
import SetPinForm from "./setPinForm";
import { addBeneficiary, getBeneficiary } from "../services/userService";
import { toast } from "react-toastify";
import { set } from "lodash";











function MyReceiveModal(props) {
  const { julAccName, ...rest } = { ...props };
  const { t } = useTranslation();


  // const qrCodeObj = {
  //   contract: "joulecoinjul",
  //   to: "jouleappcont",
  //   memo: julAccName,
  // };
  // const qrCodeString = JSON.stringify(qrCodeObj);

  const qrCodeString = julAccName;

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="m-receive modal-input"
    >
      <Modal.Header closeButton className="receive">
        {t("receive")}
      </Modal.Header>
      <Modal.Body>
        <ul className="list-inline">
          <li>
            {t("blockchain-address")} :{" "}
            <span className="receive-jcount">jouleappcont</span>
          </li>
          <li>
            {t("memo-joule-account-name")} :{" "}
            <span className="receive-jcount">{props.julAccName}</span>
          </li>
        </ul>
        {/* <Image src={Qrscan} /> */}

        <QRCode value={qrCodeString} bgColor="#000000" fgColor="#FFFFFF" />
        <p className="mt-2">
          {t("receive-joule-memo")} {props.julAccName}.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function MyShareModal(props) {
  const { availableFund, onShare, ...rest } = { ...props };
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="m-pay modal-input"
    >
      <Modal.Header closeButton>{t("pay")}</Modal.Header>
      <Modal.Body>
        <ShareForm availableFund={availableFund} onShare={onShare} onSuccess={props.onHide} />
      </Modal.Body>
    </Modal>
  );
}

function MyPayModal(props) {
  const { availableFund, onSendToEosAcc, ...rest } = { ...props };
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="m-share modal-input"
    >
      <Modal.Header closeButton>{t("withdraw")}</Modal.Header>
      <Modal.Body>
        <PayForm
          availableFund={availableFund}
          onSendToEosAcc={onSendToEosAcc}
        />
      </Modal.Body>
    </Modal>
  );
}

function MySetPinModal(props) {
  const { onSetPin, onSetSuccess, ...rest } = { ...props };
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="m-share modal-input"
    >
      <Modal.Header closeButton>{t("set_pin")}</Modal.Header>
      <Modal.Body>
        <SetPinForm onSetPin={onSetPin} onSetSuccess={onSetSuccess} />
      </Modal.Body>
    </Modal>
  );
}

// function MyPinModal(props) {
//   const { ...rest } = { ...props };
//   const { t } = useTranslation();
//   return (
//     <Modal
//       {...rest}
//       size="sm"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="m-share modal-input"
//     >
//       <Modal.Header closeButton>{t("Enter Pin")}</Modal.Header>
//       <Modal.Body>
//         <PinForm />
//       </Modal.Body>
//     </Modal>
//   );
// }
function MyJouleModal(props) {
  const { julAccName, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="m-buy-joule modal-input"
    >
      <Modal.Header closeButton>{t("buy-joule")}</Modal.Header>
      <Modal.Body>
        <JouleForm julAccName={julAccName} />
      </Modal.Body>
    </Modal>
  );
}

function ChangePassword(props) {
  const { julAccName, onChangePassword, onHide, ...rest } = { ...props };
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="m-share modal-input"
    >
      <Modal.Header closeButton>{t("change_password")}</Modal.Header>
      <Modal.Body>
        <ChangePwForm
          julAccName={julAccName}
          onChangePassword={onChangePassword}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
}

function GetTransactionType(val) {
  if (val === 1) {
    return "receive";
  }

  if (val === 2) {
    return "pay";
  }

  if (val === 3) {
    return "share";
  }

  return "receive";
}

function Myaccount({
  accType,
  portfolio,
  netPos,
  openOrders,
  onCancelOrder,
  onShare,
  onSendToEosAcc,
  onInvite,
  transfers,
  transactions,
  viewMoreTransactionBtn,
  viewMoreTransfersBtn,
  onViewMoreTransaction,
  onViewMoreTransfers,
  onLogoutClick,
  onChangePassword,
  onSetPin,
  onLogoutAllClick,
}) {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isIphone5 = useMediaQuery({ maxDeviceWidth: 320 });
  let userAccName1 = portfolio.user_name;
  let iconHeight = 45;
  if (isIphone5) {
    iconHeight = 25;
  } else if (isMobile) {
    iconHeight = 35;
  }
  const [receiveDlgShow, setReceiveDlgShow] = React.useState(false);
  const [shareDlgShow, setShareDlgShow] = React.useState(false);
  const [payDlgShow, setPayDlgShow] = React.useState(false);
  const [jouleDlgShow, setJouleDlgShow] = React.useState(false);
  const [transactionFilter, setTransactionFilter] = React.useState(0);
  const [changePassword, setChangePassword] = React.useState(false);
  const [setPinShow, setSetPinShow] = React.useState(false);
  const [beneficiary, setBeneficiary] = React.useState(false);
  // const [pinShow, setPinShow] = React.useState(false);



  var navigationtype = performance.getEntriesByType("navigation")[0].type;
  if (navigationtype == "reload") {
    //alert(accType);

  }
  else {
    sessionStorage.setItem("acctype", accType);
    //alert(accType);
  }

  let benefName = sessionStorage.getItem("benefName");

  const [placeholder, setPlaceholder] = React.useState(null)

  const [benef, setbenef] = useState({
    benefAccName: "",
  });

  function handleInputChange(e) {
    let input = benef;
    let abc = e.target.value
    console.log("test2", abc.toLowerCase())
    input[e.target.id] = (e.target.value).toLowerCase();
    // console.log(input);
    console.log(input);
    setbenef(input);
    // setPlaceholder((e.target.value).toLowerCase());
  }

  const { t, i18n } = useTranslation();
  let defaultTab = sessionStorage.getItem("acctype") === "1" ? "tradetables" : "settings";

  function handleLanguageChange({ currentTarget: input }) {
    i18n.changeLanguage(input.value);
  }
  // transactionFilter = 0 All
  // transactionFilter = 1 Receive
  // transactionFilter = 2 Share
  // transactionFilter = 3 Pay

  function IsToBeDisplayed(transferType) {
    if (transactionFilter === 0) {
      return "";
    }

    if (transactionFilter === transferType) {
      return "";
    }

    return "d-none";
  }

  function GetCurrentFilter() {
    if (transactionFilter === 0) {
      return "all";
    }

    if (transactionFilter === 1) {
      return "receive";
    }

    if (transactionFilter === 2) {
      return "pay";
    }

    if (transactionFilter === 3) {
      return "withdraw";
    }
  }

  // function onClose() {
  //   setChangePassword(false)

  // }

  return (
    <React.Fragment>
      <MyReceiveModal
        show={receiveDlgShow}
        onHide={() => setReceiveDlgShow(false)}
        julAccName={portfolio.user_name}
      />
      <MyShareModal
        availableFund={parseInt(portfolio.avl_fund) / 10000}
        onShare={onShare}
        show={shareDlgShow}
        onHide={() => setShareDlgShow(false)}
      />
      <MySetPinModal
        onSetPin={onSetPin}
        onSetSuccess={() => setSetPinShow(false)}
        show={setPinShow}
        onHide={() => setSetPinShow(false)}
      />
      {/* <MyPinModal show={pinShow} onHide={() => setPinShow(false)} /> */}
      <MyPayModal
        availableFund={parseInt(portfolio.avl_fund) / 10000}
        onSendToEosAcc={onSendToEosAcc}
        show={payDlgShow}
        onHide={() => setPayDlgShow(false)}
      />

      <MyJouleModal
        julAccName={portfolio.user_name}
        show={jouleDlgShow}
        onHide={() => setJouleDlgShow(false)}
      />

      <ChangePassword
        julAccName={portfolio.user_name}
        onChangePassword={onChangePassword}
        // onHide={onHide}
        show={changePassword}
        onHide={() => setChangePassword(false)}
      />

      <Row>
        <Col xs={12} md={6} className="myaccount">
          <div className="myaccount-bal mb-4 px-2">
            <Row>
              <Col md={4} className="my-auto">
                <h4 className="text-center">{t("my-account-balance")}</h4>
                <Image src={Balance} className="d-block mx-auto"></Image>
              </Col>
              <Col md={8}>
                <Table bordered className="mb-0">
                  <tbody>
                    <tr>
                      <td>{t("available")}</td>
                      {portfolio.avl_fund && (
                        <td>{portfolio.avl_fund / 10000} J</td>
                      )}
                    </tr>
                    <tr>
                      <td>{t("on-orders")}</td>
                      {portfolio.avl_fund && (
                        <td>{portfolio.on_order_fund / 10000} J</td>
                      )}
                    </tr>
                    <tr>
                      <td>{t("reserves")}</td>
                      {portfolio.avl_fund && (
                        <td>{portfolio.margin_fund / 10000} J</td>
                      )}
                    </tr>
                    <tr>
                      <td>{t("total")}</td>
                      {portfolio.avl_fund && (
                        <td>
                          {(parseInt(portfolio.avl_fund) +
                            parseInt(portfolio.on_order_fund) +
                            parseInt(portfolio.margin_fund)) /
                            10000}{" "}
                          J
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>

          <div className="myaccount-bg p-2 p-lg-3">
            <div className="text-center">
              <Button
                variant="info"
                className="mr-1 mr-sm-5 mr-lg-5 mr-md-2 text-uppercase myButt"
                onClick={() => setReceiveDlgShow(true)}
              >
                <Image src={Receive} height={iconHeight}></Image>
                <h6 className="receive pt-1 mb-0">{t("receive")}</h6>
              </Button>
              <Button
                variant="info"
                className="mr-1 mr-sm-5 mr-lg-5 mr-md-2 text-uppercase myButt"
                onClick={() => {
                  if (
                    portfolio.pubkey ===
                    "PUB_K1_11111111111111111111111111111111149Mr2R"
                  ) {
                    setSetPinShow(true);
                  } else {
                    setShareDlgShow(true);
                  }
                }}
              >
                <Image src={Pay} height={iconHeight + 10}></Image>
                <h6 className="pay pt-1 mb-0">{t("pay")}</h6>
              </Button>

              <Button
                variant="info"
                className="mr-1 mr-sm-5 mr-lg-5 mr-md-2 text-uppercase"
                onClick={() => setPayDlgShow(true)}
              >
                <Image src={Share} height={iconHeight}></Image>
                <h6 className="share pt-1 mb-0">{t("withdraw")}</h6>
              </Button>
              <Button
                variant="info"
                className="text-uppercase"
                onClick={() => {
                  if (
                    portfolio.pubkey ===
                    "PUB_K1_11111111111111111111111111111111149Mr2R"
                  ) {
                    setSetPinShow(true);
                  } else {
                    setJouleDlgShow(true);
                  }
                }}
              >
                <Image src={Buy} height={iconHeight}></Image>
                <h6 className="joule pt-1 mb-0">{t("buy-joule")}</h6>
              </Button>
            </div>

            <Table striped bordered className="mt-4">
              <thead>
                <tr>
                  <th colSpan="3" className="text-left pl-3 transaction">
                    {t("transaction-history")}
                  </th>

                  <th colSpan="2" className="transaction">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="float-right"
                      >
                        {t(GetCurrentFilter())}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {transactionFilter !== 0 && (
                          <Dropdown.Item
                            onClick={() => setTransactionFilter(0)}
                          >
                            {t("all")}
                          </Dropdown.Item>
                        )}
                        {transactionFilter !== 1 && (
                          <Dropdown.Item
                            onClick={() => setTransactionFilter(1)}
                          >
                            {t("receive")}
                          </Dropdown.Item>
                        )}
                        {transactionFilter !== 2 && (
                          <Dropdown.Item
                            onClick={() => setTransactionFilter(2)}
                          >
                            {t("pay")}
                          </Dropdown.Item>
                        )}
                        {transactionFilter !== 3 && (
                          <Dropdown.Item
                            onClick={() => setTransactionFilter(3)}
                          >
                            {t("withdraw")}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  {/* <th className="d-none d-sm-none d-md-none d-lg-block">
                    <span className="invisible">Type</span>
                  </th> */}
                  <th className="w-15">{t("date-time")}</th>
                  <th>{t("from")} </th>
                  <th>{t("to")}</th>
                  <th>{t("amount")}</th>
                </tr>
              </thead>
              <tbody>
                {transfers.length === 0 ? (
                  <tr>
                    <td colSpan="6">{t("no-transaction-history")}</td>
                  </tr>
                ) : (
                  transfers.map((trans) => (
                    <tr
                      className={IsToBeDisplayed(trans.transfer_type)}
                      key={trans._id}
                    >
                      <td className={GetTransactionType(trans.transfer_type)}>
                        {trans.date}{" "}
                        <span className="d-block">{trans.time}</span>
                      </td>
                      <td className={GetTransactionType(trans.transfer_type)}>
                        {trans.from}
                      </td>
                      <td className={GetTransactionType(trans.transfer_type)}>
                        {trans.to}
                      </td>
                      <td className={GetTransactionType(trans.transfer_type)}>
                        {trans.quantity}
                      </td>
                    </tr>
                  ))
                )}
                {viewMoreTransfersBtn && (
                  <tr>
                    <td colSpan="6">
                      <Button variant="success" onClick={onViewMoreTransfers}>
                        {t("view-more")}
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>

        <Col xs={12} md={6}>
          <div className="invite-bg">
            <Tabs defaultActiveKey={defaultTab} id="uncontrolled-tab-example">
              {accType === 1 && (
                <Tab eventKey="tradetables" title={t("trade-tables")}>
                  <h4>{t("net-positions")}</h4>
                  <Table className="table mt-3">
                    <thead className="text-uppercase">
                      <tr>
                        <th>{t("item-id")}</th>
                        <th>{t("item")}</th>
                        <th>{t("net")}</th>
                        <th>{t("qty")}</th>
                        <th>{t("ref-price")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {netPos.length === 0 ? (
                        <tr>
                          <td colSpan="5">{t("no-net-position")}</td>
                        </tr>
                      ) : (
                        netPos.map((item) => (
                          <tr key={item._id}>
                            <td>{item.item_disp_id}</td>
                            <td>{item.item_name}</td>
                            <td>{item.net}</td>
                            <td>{item.qty}</td>
                            <td>{item.marked_price}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>

                  <h4>{t("open-orders-on-queue")}</h4>
                  <Table className="table mt-3">
                    <thead className="text-uppercase">
                      <tr>
                        <th>{t("item-id")} </th>
                        <th>{t("item")}</th>
                        <th>{t("order")}</th>
                        <th>{t("qty")}</th>
                        <th>{t("order-price")} </th>
                        <th>{t("cancel-order")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {openOrders.length === 0 ? (
                        <tr>
                          <td colSpan="6">{t("no-open-orders")}</td>
                        </tr>
                      ) : (
                        openOrders.map((order, index) => (
                          <tr key={order._id}>
                            <td>{order.item_disp_id}</td>
                            <td>{order.item_name}</td>
                            <td>{order.pos_type}</td>
                            <td>{order.qty}</td>
                            <td>{order.order_price}</td>
                            <td>
                              {order.isCancelling ? (
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <Image
                                  src={Cancel}
                                  onClick={() => onCancelOrder(index)}
                                  className="mx-auto d-block"
                                />
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>

                  <h4>{t("trade-history")}</h4>
                  <div className=" table-responsive">
                    <Table className="table mt-3 w-100">
                      <thead className="text-uppercase">
                        <tr>
                          <th>{t("date-time")}</th>
                          <th>{t("item-id")} </th>
                          <th>{t("item")}</th>
                          <th>{t("order")}</th>
                          <th>{t("qty")}</th>
                          <th>{t("transact-price")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length === 0 ? (
                          <tr>
                            <td colSpan="6">{t("no-trade-history")}</td>
                          </tr>
                        ) : (
                          transactions.map((trx) => (
                            <tr key={trx._id}>
                              <td>
                                {trx.date}
                                <span className="d-block">{trx.time}</span>
                              </td>
                              <td>{trx.item_disp_id}</td>
                              <td>{trx.item_name}</td>
                              <td>{trx.pos_type}</td>
                              <td>{trx.qty}</td>
                              <td>{trx.match_price}</td>
                            </tr>
                          ))
                        )}

                        {viewMoreTransactionBtn && (
                          <tr>
                            <td colSpan="6">
                              <Button
                                variant="success"
                                onClick={onViewMoreTransaction}
                              >
                                {t("view-more")}
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
              )}
              {accType === 1 && (
                <Tab eventKey="invitefriend" title={t("invite-friend")}>
                  <div className="invite-friend py-4">
                    <InviteForm onInvite={onInvite} />
                  </div>
                </Tab>
              )}

              <Tab eventKey="faq" title={t("faq")}>
                <Faq />
              </Tab>
              <Tab eventKey="settings" title={t("settings")}>
                <Col md={12} className="mb-3 px-0 px-md-2">
                  <div className="language-sel ">
                    <Form>
                      <Form.Group controlId="selectLanguage">
                        <Form.Label>{t("language")}</Form.Label>
                        <Form.Control
                          as="select"
                          value={i18n.language}
                          onChange={handleLanguageChange}
                        >
                          <option value="en">English</option>
                          <option value="zh-CN">简体中文</option>
                          <option value="zh-TW">繁體中文</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </div>
                  <Button
                    variant="success"
                    className="mr-2"
                    onClick={() => setChangePassword(true)}
                  >
                    {t("change_password")}
                  </Button>

                  <Modal
                    show={beneficiary}
                    onHide={() => setBeneficiary(false)}
                    className="forum-post"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="mt-0">
                      <h4 className="mb-4">{t("beneficiary")}</h4>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          {/* <div className="row">
                            <div className="col-md-8 pr-0">I support this organization account:</div>
                            <div className="col-md-4 pl-0"> <Form.Control
                              type="text"
                              placeholder={"Beneficiary"}
                              id="benefAccName"

                              onChange={handleInputChange}
                            /></div>
                            <p className="mt-4">(System will donate 10% of your transaction fees each month to them.)</p>

                          </div> */}

                          <div className="d-flex align-items-center justify-content-center"> {t("beneficiary1")}
                            <Form.Control
                              type="text"
                              placeholder={placeholder}
                              id="benefAccName"
                              className="w-25 "
                              onChange={handleInputChange}
                            /></div>
                          <p className="mt-2">{t("beneficiary2")}</p>
                        </Form.Group>


                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        className="mt-4"
                        onClick={async () => {
                          try {
                            console.log(benef);
                            let benefAccName = benef.benefAccName;
                            let userAccName = portfolio.user_name

                            let data2 = await addBeneficiary(benefAccName, userAccName);
                            setBeneficiary(false);
                            if (data2.data.id) {
                              toast.error(data2.data)
                            }
                            else {
                              toast.success("Beneficiary Added")
                            }

                            console.log(data2);

                            try {
                              // console.log(userAccName)
                              let data = await getBeneficiary(userAccName);
                              window.sessionStorage.setItem("benefName", data.data.benefAccName);

                              // console.log(benef);
                              // setbenef(data.benefAccName)
                              // console.log("test", data._id)
                              console.log("test", data.data.benefAccName);
                            }
                            catch (ex) {
                              console.log(ex)
                              if (ex.response) {
                                toast.error(ex.response.data);
                              }
                            }
                            // toast.success(data2)


                          } catch (ex) {
                            if (ex.response) {
                              toast.error(ex.response.data);
                            }
                          }
                        }}
                      >
                        {t("submit")}
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {accType === 1 && (
                  <Button
                    variant="success"
                    className="mr-2"
                    onClick={async () => {
                      let benefName = sessionStorage.getItem("benefName");
                      setPlaceholder(benefName);
                      setBeneficiary(true)
                    }
                    }
                  >
                    {t("beneficiary")}
                  </Button>
                  )}
                  
                  <Button
                    variant="success"
                    className="sign-out1 "
                    onClick={onLogoutAllClick}
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="pr-2 float-left"
                    />
                    {t("logout")}
                  </Button>

                </Col>
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Myaccount;

