import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import "./common/common.css";
import { Row, Col, Image, Modal } from "react-bootstrap";
import Qrcode from "../assets/qrcode.png";
import QrReader from "react-qr-reader";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PinInput from "react-pin-input";

function is_valid_account_name(account_name) {
  let re = new RegExp("^([a-z1-5.]){1,12}$");
  return re.test(account_name);
}

class PayForm extends Form {
  state = {
    data: { to_address: "", amount: "", memo: "" },
    loading: false,
    errors: {},
    result: "",
    showQrCodeReader: false,
    passcode: "",
    errorPasscode: "",
  };

  schema = {
    to_address: Joi.string()
      .required()
      .label(this.props.t("receiver-blockchain-address"))
      .regex(/^([a-z1-5.]){1,12}$/)
      .error(() => {
        return {
          message:
            "Only a-z and 1-5 are allowed and length of name should not be greater than 12.",
        };
      }),
    amount: Joi.number()
      .required()
      .label(this.props.t("amount"))
      .max(this.props.availableFund),
    memo: Joi.string().allow(""),
  };

  doSubmit = async () => {
    const { t } = this.props;

    // Call the server
    console.log("Submitted");
    if (this.state.passcode.length !== 4) {
      // passcode length is
      this.setState({ errorPasscode: t("4-digit-present") });
      return;
    }

    this.setState({ loading: true });

    await this.props.onSendToEosAcc(
      this.state.data.to_address,
      this.state.data.amount,
      this.state.data.memo,
      this.state.passcode
    );
    this.setState({ loading: false });
  };
  handleScan = (result) => {
    if (result) {
      if (is_valid_account_name(result)) {
        let data = { ...this.state.data };
        data.to_address = result;
        this.setState({ data });
      } else {
        toast.error(this.props.t("invalid-qr-code"));
      }

      //TODO validate the data
      this.handleCloseQrCodeReader();
    }
  };
  handleError = (err) => {
    this.setState({ showQrCodeReader: false });
    toast.error(err.message);
  };

  handleCloseQrCodeReader = () => {
    this.setState({ showQrCodeReader: false });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-input"
          show={this.state.showQrCodeReader}
          onHide={this.handleCloseQrCodeReader}
        >
          <Modal.Header closeButton>{t("scan-qr-code")}</Modal.Header>
          <Modal.Body>
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: "100%" }}
            />
          </Modal.Body>
        </Modal>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 10, order: 1 }}
              lg={{ span: 10, order: 1 }}
            >
              {this.renderSimpleInput(
                "to_address",
                t("receiver-blockchain-address"),
                "text",
                { textTransform: "lowercase" }
              )}
              {this.renderSimpleInput("amount", t("amount"), "number")}
            </Col>
            <Col
              className="my-auto"
              xs={{ span: 12, order: 1 }}
              md={{ span: 2, order: 2 }}
              lg={{ span: 2, order: 2 }}
            >
              <h6>{t("scan-qr-code")}</h6>
              <div className="qr-code-btn-img">
                <Image
                  src={Qrcode}
                  className="img-fluid"
                  onClick={() => this.setState({ showQrCodeReader: true })}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10} lg={10}>
              {this.renderSimpleInput("memo", t("memo-optional"))}
              <Row className="pl-3">
                <label>{t("enter_4_digit_pin")}</label>
              </Row>
              <Row className="pl-3">
                <PinInput
                  length={4}
                  initialValue=""
                  secret
                  onChange={(value, index) =>
                    this.setState({ passcode: value })
                  }
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{ borderColor: "#009688" }}
                  inputFocusStyle={{ borderColor: "#fff" }}
                  onComplete={(value, index) => { }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                {this.state.errorPasscode && (
                  <div className="alert alert-primary">
                    {this.state.errorPasscode}
                  </div>
                )}
              </Row>
              {this.renderButton(t("withdraw"))}
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }
}
const PayFormWithLang = withTranslation()(PayForm);
export default PayFormWithLang;
