//import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl } from "../config";
import ecc from "eosjs-ecc";
import aesjs from "aes-js";

var encryptKey = [
  58, 39, 64, 20, 86, 91, 143, 120, 45, 10, 22, 77, 60, 52, 34, 99,
];

http.setJwt(getJwt());

const randomSeed =
  "53FcRmstl6eatzdZyq4YXljtAJRIF6w5WqH1wAhFoTtrzWrbwe7Zz8kchMVw";

export function getKey(julAccName, password) {
  return ecc.seedPrivate(julAccName + password + randomSeed);
}

export function getPublicKey(privateKey) {
  return ecc.privateToPublic(privateKey);
}

export function getWithdrawalKey(pin, privateKey) {
  return ecc.seedPrivate(pin + privateKey);
}

function GetEncryptedHexString(userObj) {
  const userString = JSON.stringify(userObj);
  // console.log('userString', userString);
  const textBytes = aesjs.utils.utf8.toBytes(userString);
  // console.log('textBytes', textBytes);
  const aesCtr = new aesjs.ModeOfOperation.ctr(
    encryptKey,
    new aesjs.Counter(5)
  );

  let encryptedBytes = aesCtr.encrypt(textBytes);
  // console.log('encryptedBytes', encryptedBytes);
  return aesjs.utils.hex.fromBytes(encryptedBytes);
}

function GetDecryptedObject(encryptedHexString) {
  let encryptedBytes = aesjs.utils.hex.toBytes(encryptedHexString);
  // console.log('encryptedBytes', encryptedBytes);
  const aesCtr = new aesjs.ModeOfOperation.ctr(
    encryptKey,
    new aesjs.Counter(5)
  );
  let decryptedBytes = aesCtr.decrypt(encryptedBytes);
  // console.log('decryptedBytes', decryptedBytes);
  let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  // console.log('decryptedText', decryptedText);
  return JSON.parse(decryptedText);
}

export async function login(julAccName, password) {
  const privateKey = await getKey(julAccName, password);

  let d = new Date();
  // console.log(d.getTime());
  const ts = d.getTime().toString();
  const signature = ecc.sign(ts, privateKey);
  const apiEndpoint = apiUrl + "auth/";
  let { data: user } = await http.post(apiEndpoint, {
    julAccName,
    ts,
    signature,
  });
  user.key = privateKey;
  localStorage.setItem("user", GetEncryptedHexString(user));
  http.setJwt(getJwt());
}

export async function updateKey(julAccName, password) {
  const privateKey = await getKey(julAccName, password);
  const user = getCurrentUser();
  user.key = privateKey;
  localStorage.setItem("user", GetEncryptedHexString(user));
}

export function updateAccCreatedStatus() {
  const user = getCurrentUser();
  user.isAccCreated = true;
  localStorage.setItem("user", GetEncryptedHexString(user));
}

export function logout() {
  localStorage.removeItem("user");
  http.setJwt(getJwt());
}

export function updateUser(user) {
  localStorage.setItem("user", GetEncryptedHexString(user));
}

export function getCurrentUser() {
  try {
    const encryptedHex = localStorage.getItem("user");
    const user = GetDecryptedObject(encryptedHex);
    return user;
  } catch (ex) {
    console.log("No user login found");
    return null;
  }
}

export function getJwt() {
  try {
    const encryptedHex = localStorage.getItem("user");
    const user = GetDecryptedObject(encryptedHex);
    return user.token;
  } catch (ex) {
    console.log("No jwt found");
    return null;
  }
}
