import React from "react";
import { useTranslation } from "react-i18next";

const Input = ({ name, label, error, ...rest }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="form-group has-float-label">
        {/* <label htmlFor={name}>{label}</label> */}
        <input {...rest} name={name} id={name} className="form-control" />
        <label htmlFor={name}>{label}</label>
      </div>
      {error && <div className="alert alert-primary">{t(error)}</div>}
    </React.Fragment>
  );
};
export default Input;
