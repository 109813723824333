import React from "react";
import Form from "./common/form";
import "./common/common.css";
import { withTranslation } from "react-i18next";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import PinInput from "react-pin-input";
class SetPinForm extends Form {

  state = {
    passcode: "",
    confirmPasscode: "",
    errorPasscode: null,
    errorConfirmPasscode: null,
    loading: false,
  };

  doSubmit = async (e) => {
    const { t } = this.props;

    // Call the server
    e.preventDefault();
    console.log("Submitted");
    if (this.state.passcode.length !== 4) {
      // passcode length is
      this.setState({ errorPasscode: t("four_digit_error") });
      return;
    }
    this.setState({ errorPasscode: null });
    if (this.state.confirmPasscode.length !== 4) {
      // passcode length is
      this.setState({ errorConfirmPasscode: t("four_digit_error") });
      return;
    }

    if (this.state.passcode !== this.state.confirmPasscode) {
      this.setState({
        errorConfirmPasscode: t("pin_match_error"),
      });
      return;
    }
    this.setState({ errorConfirmPasscode: null });

    this.setState({ loading: true });
    await this.props.onSetPin(this.state.passcode);
    console.log(this.state);
    this.setState({ loading: false });
    this.props.onSetSuccess();
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Container fluid>
          <Col md={4} className="mx-auto text-center">
            <Row>
              <label className="mx-auto d-block">{t("enter_new_passcode")}</label>
            </Row>
            <Row>
              <PinInput
                length={4}
                initialValue=""
                secret
                onChange={(value, index) => this.setState({ passcode: value })}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "#009688" }}
                inputFocusStyle={{ borderColor: "#fff" }}
                onComplete={(value, index) => { }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              {this.state.errorPasscode && (
                <div className="alert alert-primary">
                  {this.state.errorPasscode}
                </div>
              )}
            </Row>
            <Row>
              <label className="mx-auto d-block mt-3">{t("confirm_passcode")}</label>
            </Row>
            <Row>
              <PinInput
                length={4}
                initialValue=""
                secret
                onChange={(value, index) =>
                  this.setState({ confirmPasscode: value })
                }
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "#009688" }}
                inputFocusStyle={{ borderColor: "#fff" }}
                onComplete={(value, index) => { }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              {this.state.errorConfirmPasscode && (
                <div className="alert alert-primary">
                  {this.state.errorConfirmPasscode}
                </div>
              )}
            </Row>

            {this.state.loading === false ? (
              <button
                onClick={this.doSubmit}
                className="btn btn-success mx-auto d-block mt-3"
              >
                {t("set_passcode")}
              </button>
            ) : (
                <button className="btn btn-success mx-auto d-block" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                Loading..
                </button>
              )}
          </Col>
        </Container>
      </React.Fragment>
    );
  }
}

const SetPinFormWithLang = withTranslation()(SetPinForm);
export default SetPinFormWithLang;
