import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import { paypalAppId } from "../config";
import { Spinner } from "react-bootstrap";
const CLIENT_ID =
  paypalAppId.env === "sandbox" ? paypalAppId.sandbox : paypalAppId.production;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: this.props.julAccName,
          amount: {
            currency_code: "USD",
            value: parseFloat(this.props.amount),
          },
        },
      ],
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then((details) => {
      console.log(details);
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID,
      };
      console.log("Payment Approved: ", paymentData);
      if (this.props.onSuccess) {
        this.props.onSuccess(details);
      }
    });
  };

  onCancel = (data) => {
    console.log("payment cancelled");
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    const { showButtons, loading } = this.state;

    return (
      // {loading && <Spinner />}
      <div className="px-2 py-2" style={{ background: "#ccc" }}>
        {loading && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        )}
        {showButtons && (
          <PayPalButton
            createOrder={(data, actions) => this.createOrder(data, actions)}
            onApprove={(data, actions) => this.onApprove(data, actions)}
            onCancel={(data) => this.onCancel(data)}
          />
        )}
      </div>
    );
  }
}

export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`
)(PaypalButton);
