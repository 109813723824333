import React, { Suspense } from "react";
import "./App.css";
import SignInForm from "./components/signInForm";
import SignUpForm from "./components/signUpForm";
import Home from "./components/home";
import { Route, Redirect, Switch, IndexRoute } from "react-router-dom";
import NotFound from "./components/notFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Merchant from "./components/merchant"

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Suspense fallback="loading">
        <Switch>
          <Route path="/register" component={SignUpForm} />
          <Route path="/login" component={SignInForm} />
          <Route path="/not-found" component={NotFound} />
          <Route path="/" exact component={Home} />
          {/* <Route path="/merchant" component={Merchant} /> */}
          <Redirect to="/not-found" />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
