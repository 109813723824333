import {
  JsonRpc
} from "eosjs";
import {
  blockchain
} from "../config"

export const rpc = new JsonRpc(
  blockchain.nodeosEndpoint
);

export async function GetItems() {
  const {
    rows: allItems
  } = await rpc.get_table_rows({
    json: true,
    code: blockchain.contract, // Contract that we target
    scope: blockchain.contract, // Account that owns the data
    table: "tblitem", // Table name
    limit: 100 // Maximum number of rows that we want to get
  });

  return allItems
}

export async function GetOrderMatch() {
  const {
    rows: items_ord_mat
  } = await rpc.get_table_rows({
    json: true,
    code: blockchain.contract, // Contract that we target
    scope: blockchain.contract, // Account that owns the data
    table: "tblordmat", // Table name
    limit: 100, // Maximum number of rows that we want to get
  });

  return items_ord_mat;
}


export async function GetEosJulRecv(vAccount) {
  const {
    rows: eos_jul_recv
  } = await rpc.get_table_rows({
    json: true,
    code: blockchain.contract, // Contract that we target
    scope: blockchain.contract, // Account that owns the data
    table: "eosjulrecv", // Table name
    lower_bound: vAccount,
    upper_bound: vAccount
  });

  return eos_jul_recv;
}