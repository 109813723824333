import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import "./common/common.css";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class InviteForm extends Form {
  state = {
    data: { email: "" },
    loading: false,
    errors: {},
  };

  schema = {
    email: Joi.string().email().required().label(this.props.t("email-id")),
  };

  doSubmit = async () => {
    // Call the server
    console.log("Submitted");
    this.setState({ loading: true });
    await this.props.onInvite(this.state.data.email);
    this.setState({ loading: false });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col md={12} className="mx-auto">
            <form onSubmit={this.handleSubmit}>
              {this.renderSimpleInput("email", t("email-id"))}
              {this.renderButton(t("send-invitation"))}
            </form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const InviteFormWithLang = withTranslation()(InviteForm);
export default InviteFormWithLang;
