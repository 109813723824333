import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import "./common/common.css";
import { withTranslation } from "react-i18next";

class PlaceOrderForm extends Form {
  state = {
    data: { shares: "1" },
    errors: {},
    loading: false,
  };

  schema = {
    shares: Joi.number().integer().label("No. of shares"),
  };

  doSubmit = async () => {
    // Call the server
    console.log("Submitted");
    this.setState({ loading: true });
    await this.props.onOrderPlaced(this.state.data.shares);
    this.setState({ loading: false });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          {this.renderSimpleInput("shares", t("enter-no-shares"), "number")}
          {this.renderButton(t("place-order"))}
        </form>
      </React.Fragment>
    );
  }
}

const PlaceOrderFormWithLang = withTranslation()(PlaceOrderForm);
export default PlaceOrderFormWithLang;
