

import React from "react";
import {
  Tabs,
  Row,
  Container,
  Col,
  Image,
  Spinner,
  Table,
  Tab,
} from "react-bootstrap";
import Item from "./item";
import NavigationBar from "./navigationBar";
import { categories_init } from "./categories.json";
import {
  getCurrentUser,
  logout,
  getWithdrawalKey,
  getPublicKey,
  updateUser,
} from "../services/authService";
import MyAccount from "./myaccount";
import {
  GetItems,
  GetOrderMatch,
  GetEosJulRecv,
} from "../services/blockchainService";
import PlaceOrderForm from "./placeOrderForm";
import Modal from "react-bootstrap/Modal";
import Cancel from "../assets/cancel.png";
import {
  PlaceOrder,
  getMyPortfolio,
  getOrders,
  cancelOrder,
  share,
  sendToEosAcc,
  eosJulClaim,
  setPubKey,
} from "../services/dappClientService";
import {
  sendInvite,
  getTransfers,
  getTransactions,
  getWatchlist,
  addToWatchlist,
  deleteFromWatchlist,
  logoutAllSession,
} from "../services/userService";
import { toast } from "react-toastify";
import { Animated } from "react-animated-css";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Merchant from "./merchant";

var currPage = "";
var navigationtype = performance.getEntriesByType("navigation")[0].type;

// sessionStorage.setItem("currPage", "home");

if (navigationtype == "reload") {
  currPage = sessionStorage.getItem("currPage");
}
else {
  currPage = "home";
  sessionStorage.setItem("currPage", "home");
}

const numElementsInTable = 10;
class Home extends React.Component {
  state = {
    categories: [],
    user: {},
    items: [],
    items_ord_mat: [],
    items_display: [],
    navBarText: "Login",
    isLoggedIn: false,
    //currentPage: "home",
    currentPage: currPage,
    showPlaceOrder: false,
    placeOrderInfo: { pos_type: 1, item_id: 1, price: 500, item_name: "" },
    intervalId: 0,
    portfolio: {},
    netPos: [],
    openOrders: [],
    transactions: [],
    transactionsDisplay: [],
    viewMoreTransactionBtn: false,
    transfers: [],
    transfersDisplay: [],
    viewMoreTransfersBtn: false,
    showOpenOrders: false,
    openOrderDetails: [],
  };

  eosJulClaimInProgress = false;

  async updateItems() {

    console.log("TEST", this.state.user);
    const items = await GetItems();
    const items_ord_mat = await GetOrderMatch();
    //console.log(items);
    //console.log(items, items_ord_mat);
    const items_display = items.map((item) => {
      const trade = [];
      const item_disp = {};
      const midPrice = Math.round((item.best_l_price + item.best_s_price) / 2);
      const lowestPrice = midPrice - 5 < 0 ? 0 : midPrice - 5;
      const highestPrice = midPrice + 5 > 1000 ? 1000 : midPrice + 5;

      const itemOrdMat = items_ord_mat.find((itm) => {
        return itm.item_id === item.id;
      });

      for (let i = highestPrice; i >= lowestPrice; i--) {
        const priceRow = {};
        //update price
        priceRow.price = i;
        //update market price indicator
        priceRow.isMarketPrice = i === item.market_price ? true : false;
        //update long and short quantity
        const priceOrd = itemOrdMat.price_list.find((p) => p.price === i);
        if (priceOrd === undefined) {
          priceRow.longQty = 0;
          priceRow.shortQty = 0;
        } else {
          if (priceOrd.offer_qty > 0) {
            priceRow.longQty = priceOrd.offer_qty;
            priceRow.shortQty = 0;
          } else {
            priceRow.shortQty = Math.abs(priceOrd.offer_qty);
            priceRow.longQty = 0;
          }
        }
        priceRow.upArrowBtn = false;
        priceRow.downArrowBtn = false;
        trade.push(priceRow);
      }

      for (let i = 0; i < trade.length; i++) {
        trade[i].downArrowBtn = true;
        if (trade[i].longQty > 0) {
          break;
        }
      }

      for (let i = 0; i < trade.length; i++) {
        trade[trade.length - 1 - i].upArrowBtn = true;
        if (trade[trade.length - 1 - i].shortQty > 0) {
          break;
        }
      }
      item_disp.item = item;
      item_disp.item.trade = trade;
      return item_disp;
    });

    let categories = [...categories_init];

    categories.forEach((category, index) => {
      if (index === 0) {
        if (this.state.isLoggedIn) {
          const items = items_display.filter((item) =>
            this.state.user.watchList.includes(item.item.id)
          );
          categories[index].items = items;
        }
      } else {
        const items = items_display.filter(
          (item) => item.item.category_id === category.id
        );
        items.sort(function (a, b) {
          return a.item.display_id - b.item.display_id;
        });

        categories[index].items = items;
      }
    });
    this.setState({ items, items_ord_mat, items_display, categories });

    if (this.state.isLoggedIn && this.state.user.julAccName) {

      let netPos,
        transfers,
        transactions,
        transfersDisplay,
        watchlist,
        transactionsDisplay = [];
      let openOrders = [];
      let portfolio = {};
      let viewMoreTransfersBtn,
        viewMoreTransactionBtn = false;
      try {
        //getWatchlist
        // const { data: result } = await getWatchlist();
        // watchlist = result.map((trans) => {
        //   let transDisp = {};
        //     transDisp = trans;
        //   console.log(transDisp)
        // });
        // console.log("THIS IS WATCHLIST" + watchlist); 

        portfolio = await getMyPortfolio(this.state.user.julAccName);
        // console.log(portfolio);
        if (portfolio) {
          console.log("TEST2")

          this.setState({ portfolio });
          netPos = portfolio.net_pos.map((item) => {
            const itemPos = {};
            itemPos._id = item.item_id;
            itemPos.item_disp_id = items.find(
              (itm) => itm.id === parseInt(item.item_id)
            ).display_id;
            itemPos.item_name = items.find(
              (itm) => itm.id === parseInt(item.item_id)
            ).name;
            itemPos.net = item.position > 0 ? "Long" : "Short";
            itemPos.qty = Math.abs(item.position);
            itemPos.marked_price = (item.marked_price / 10).toFixed(1);
            return itemPos;
          });

          //also update the net position and open orders
          // get all the user orders
          if (portfolio.order_ids.length > 0) {
            const orders = await getOrders(portfolio.order_ids);
            if (orders) {
              openOrders = orders.map((order) => {
                const orderDisplay = {};
                orderDisplay._id = order.id;
                orderDisplay.item_id = order.item_id;
                orderDisplay.item_disp_id = items.find(
                  (itm) => itm.id === parseInt(order.item_id)
                ).display_id;
                orderDisplay.item_name = items.find(
                  (itm) => itm.id === parseInt(order.item_id)
                ).name;
                orderDisplay.pos_type = order.pos_type === 1 ? "Long" : "Short";
                orderDisplay.qty = order.pend_qty;
                orderDisplay.order_price = (order.price / 10).toFixed(1);
                orderDisplay.isCancelling = false;
                return orderDisplay;
              });
            }
          }

   



          // get transfers
          const { data: result } = await getTransfers();
          transfers = result.map((trans) => {
            let transDisp = {};
            transDisp = trans;
            transDisp.quantity = transDisp.quantity.slice(0, -6) + " J";

            transDisp.date = moment(trans.date_time).format("YYYY/MM/DD");
            transDisp.time = moment(trans.date_time).format("HH:mm");
            return transDisp;
          });

          if (transfers.length > numElementsInTable) {
            transfersDisplay = transfers.slice(
              0,
              this.state.transfersDisplay.length > 0
                ? this.state.transfersDisplay.length
                : numElementsInTable
            );
            if (transfersDisplay.length < transfers.length) {
              viewMoreTransfersBtn = true;
            }
          } else {
            transfersDisplay = transfers;
            viewMoreTransfersBtn = false;
          }
          // console.log(transfersDisplay);

          const { data: result1 } = await getTransactions();
          let filteredTransactions = result1.filter((trx) => {
            if (
              items.findIndex((itm) => itm.id === parseInt(trx.item_id)) != -1
            ) {
              return true;
            }
            return false;
          });
          transactions = filteredTransactions.map((trx) => {
            const trxDisp = {};
            trxDisp._id = trx._id;
            trxDisp.item_id = trx.item_id;
            trxDisp.item_disp_id = items.find(
              (itm) => itm.id === parseInt(trx.item_id)
            ).display_id;
            trxDisp.item_name = items.find(
              (itm) => itm.id === parseInt(trx.item_id)
            ).name;
            trxDisp.pos_type = trx.pos_type === 1 ? "Long" : "Short";
            trxDisp.qty = trx.match_qty;
            trxDisp.match_price = (trx.price / 10).toFixed(1);
            trxDisp.date = moment(trx.date_time).format("YYYY/MM/DD");
            trxDisp.time = moment(trx.date_time).format("HH:mm");

            return trxDisp;
          });

          if (transactions.length > numElementsInTable) {
            transactionsDisplay = transactions.slice(
              0,
              this.state.transactionsDisplay.length > 0
                ? this.state.transactionsDisplay.length
                : numElementsInTable
            );
            if (transactionsDisplay.length < transactions.length) {
              viewMoreTransactionBtn = true;
            }
          } else {
            transactionsDisplay = transactions;
            viewMoreTransactionBtn = false;
          }
        } else {
          // call account creation
        }
      } catch (ex) {
        console.error(ex);
      }

      // console.log(netPos, openOrders);
      this.setState({
        portfolio,
        netPos,
        openOrders,
        transfers,
        transactions,
        transactionsDisplay,
        transfersDisplay,
        viewMoreTransactionBtn,
        viewMoreTransfersBtn,
      });

      const result = await GetEosJulRecv(this.state.user.julAccName);
      if (result.length > 0) {
        console.log("eos jul recv available");
        if (this.eosJulClaimInProgress === false) {
          this.eosJulClaimInProgress = true;
          await eosJulClaim(this.state.user.julAccName, this.state.user.key);
          this.eosJulClaimInProgress = false;
        }
      }

    }
  }

  refreshPage = () => {
    this.updateItems();
  };
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  componentDidMount() {
    let intervalId = setInterval(this.refreshPage, 10000);
    this.setState({ intervalId: intervalId });

    const user = getCurrentUser();
    if (user) {
      this.setState({ user, navBarText: user.julAccName, isLoggedIn: true });
    }
    // get the list of items
    this.updateItems();
  }

  handleMyAccountClick = () => {
    // console.log(this.state.navBarText);
    if (this.state.navBarText === "Login") {
      window.location = "/login";
    } else {
      if (this.state.currentPage !== "myaccount") {
        this.setState({ currentPage: "myaccount" });
        sessionStorage.setItem("currPage", "myaccount");
      }

    }
  };

  handleLogoClick = () => {
    if (this.state.currentPage !== "home") {
      clearInterval(this.state.intervalId);
      let intervalId = setInterval(this.refreshPage, 10000);
      this.setState({ intervalId: intervalId });
      this.setState({ currentPage: "home" });
      sessionStorage.setItem("currPage", "home");
    }
  };

  handleMerchantClick = () => {
    if (this.state.currentPage !== "merchant") {
      clearInterval(this.state.intervalId);
      this.setState({ currentPage: "merchant" });
      sessionStorage.setItem("currPage", "merchant");
      // console.log(sessionStorage.getItem("currPage"))

    }
  };

  handleLogoutClick = () => {
    const res = window.confirm(this.props.t("are-you-sure-want-to-logout"));
    console.log(res);
    if (res) {
      logout();
      window.location = "/";
    }
  };

  handleLogoutAllClick = async () => {
    const res = window.confirm(this.props.t("are-you-sure-want-to-logout"));
    console.log(res);
    if (res) {
      await logoutAllSession();
      logout();
      window.location = "/";
    }
  };

  handleChangePassword = () => {
    const user = getCurrentUser();
    if (user) {
      this.setState({ user });
    }
    // const res = window.confirm(this.props.t("are-you-sure-want-to-logout"));
    // console.log(res);
    // <div>
    //   <button type="button" onClick={handleOpen}>
    //     Open Modal
    // </button>
    //   <Modal
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="simple-modal-title"
    //     aria-describedby="simple-modal-description"
    //   >
    //     {body}
    //   </Modal>
    // </div>
  };

  handleClosePlaceOrder = () => {
    this.setState({ showPlaceOrder: false });
  };

  handleCloseOpenOrders = () => {
    this.setState({ showOpenOrders: false });
  };

  handleOrderPlaced = async (shares) => {
    const { placeOrderInfo } = { ...this.state };
    const result = await PlaceOrder(
      placeOrderInfo.item_id,
      this.state.user.julAccName,
      placeOrderInfo.price,
      placeOrderInfo.pos_type,
      shares,
      this.state.user.key
    );
    if (result.success) {
      toast.success(this.props.t(result.msg));
    } else {
      toast.error(this.props.t(result.msg));
    }
    await this.updateItems();
    this.handleClosePlaceOrder();
  };

  handlePlaceOrder = (placeOrderInfo) => {
    if (this.state.isLoggedIn) {
      if (this.state.user.accType === 1) {
        this.setState({ placeOrderInfo, showPlaceOrder: true });
      }
    } else {
      toast.error(this.props.t("login-to-place-order"));
    }
  };

  handleOpenOrder = (openOrderDetails) => {
    if (this.state.isLoggedIn) {
      this.setState({ openOrderDetails, showOpenOrders: true });
      console.log(openOrderDetails);
    } else {
      // toast.error(this.props.t("login-to-place-order"));
    }
  };

  handleWatchlist = async (item_id) => {
    if (!this.state.user.watchList.includes(item_id)) {
      try {
        await addToWatchlist(item_id);
        let user = this.state.user;
        user.watchList.push(item_id);
        let categories = this.state.categories;
        const items = this.state.items_display.filter((item) =>
          user.watchList.includes(item.item.id)
        );
        categories[0].items = items;
        updateUser(user);
        this.setState({ user, categories });
      } catch (ex) {
        console.log(ex.message);
        toast.error(this.props.t("failed-to-add-to-watchlist"));
      }
    } else {
      try {
        await deleteFromWatchlist(item_id);
        let user = this.state.user;
        let index = user.watchList.indexOf(item_id);
        if (index !== -1) user.watchList.splice(index, 1);
        let categories = this.state.categories;
        const items = this.state.items_display.filter((item) =>
          user.watchList.includes(item.item.id)
        );
        categories[0].items = items;
        updateUser(user);
        this.setState({ user, categories });
      } catch (ex) {
        console.log(ex.message);
        toast.error(this.props.t("failed-to-remove-to-watchlist"));
      }
    }
  };

  handleCancelOrder = async (index) => {
    const openOrders = [...this.state.openOrders];
    openOrders[index].isCancelling = true;
    clearInterval(this.state.intervalId);
    this.setState({ openOrders });

    const result = await cancelOrder(
      this.state.user.julAccName,
      this.state.openOrders[index]._id,
      this.state.user.key
    );

    let openOrderDetails = [...this.state.openOrderDetails];

    if (result.success) {
      const openOrderIndex = openOrderDetails.indexOf(index);
      if (openOrderIndex > -1) {
        openOrderDetails.splice(openOrderIndex, 1);
      }
      toast.success(this.props.t(result.msg));
    } else {
      toast.error(this.props.t(result.msg));
    }
    this.setState({ openOrderDetails });
    await this.updateItems();
    let intervalId = setInterval(this.refreshPage, 10000);
    this.setState({ intervalId: intervalId });
  };

  // handleCancelOpenOrder = async (index) => {
  //   const openOrderDetails = [...this.state.openOrderDetails];
  //   openOrderDetails[index].isCancelling = true;
  //   clearInterval(this.state.intervalId);
  //   this.setState({ openOrderDetails });

  //   const result = await cancelOrder(
  //     this.state.user.julAccName,
  //     this.state.openOrderDetails[index]._id,
  //     this.state.user.key
  //   );
  //   if (result.success) {
  //     toast.success(this.props.t(result.msg));
  //   } else {
  //     toast.error(this.props.t(result.msg));
  //   }
  //   await this.updateItems();

  //   let intervalId = setInterval(this.refreshPage, 10000);
  //   this.setState({ intervalId: intervalId });
  // };

  handleShare = async (toJulAccName, amount, pin) => {
    let key = getWithdrawalKey(pin, this.state.user.key);
    const result = await share(
      this.state.user.julAccName,
      toJulAccName,
      amount,
      key
    );
    if (result.success) {

      toast.success(this.props.t(result.msg));
    } else {
      if (result.msg === "wrong public key") {
        toast.error(this.props.t("wrong-pin"));
      }
      else {
        toast.error(this.props.t(result.msg));
      }
    }
    await this.updateItems();
  };

  handleSetPin = async (pin) => {
    let key = getWithdrawalKey(pin, this.state.user.key);
    let publicKey = getPublicKey(key);

    const result = await setPubKey(
      this.state.user.julAccName,
      publicKey,
      this.state.user.key
    );
    if (result.success) {
      toast.success(this.props.t(result.msg));
    } else {
      toast.error(this.props.t(result.msg));
    }
    await this.updateItems();
  };

  handleSendToEosAcc = async (toEosAccName, amount, memo, pin) => {
    console.log("send to eos acc", toEosAccName, amount, memo);
    let key = getWithdrawalKey(pin, this.state.user.key);

    const result = await sendToEosAcc(
      this.state.user.julAccName,
      toEosAccName,
      amount,
      memo,
      key
    );
    if (result.success) {

      toast.success(this.props.t(result.msg));
    } else {
      if (result.msg === "wrong public key") {
        toast.error(this.props.t("wrong-pin"));
      } else {
        toast.error(this.props.t(result.msg));
      }
    }
    await this.updateItems();
  };

  handleInvite = async (email) => {
    try {
      const { data } = await sendInvite(email, this.state.user._id);
      console.log(data);
      toast.success(this.props.t("invitation-sent-successfully"));
    } catch (ex) {
      if (ex.response) {
        toast.error(ex.response.data);
        console.log(ex.response);
      }
    }
  };

  handleViewMoreTransaction = () => {
    let transactionsDisplay;
    let viewMoreTransactionBtn = false;
    if (
      this.state.transactionsDisplay.length + numElementsInTable <
      this.state.transactions.length
    ) {
      transactionsDisplay = this.state.transactions.slice(
        0,
        this.state.transactionsDisplay.length + numElementsInTable
      );
      viewMoreTransactionBtn = true;
    } else {
      transactionsDisplay = this.state.transactions;
    }
    this.setState({ viewMoreTransactionBtn, transactionsDisplay });
  };

  handleViewMoreTransfers = () => {
    let transfersDisplay;
    let viewMoreTransfersBtn = false;
    if (
      this.state.transfersDisplay.length + numElementsInTable <
      this.state.transfers.length
    ) {
      transfersDisplay = this.state.transfers.slice(
        0,
        this.state.transfersDisplay.length + numElementsInTable
      );
      viewMoreTransfersBtn = true;
    } else {
      transfersDisplay = this.state.transfers;
    }

    this.setState({ viewMoreTransfersBtn, transfersDisplay });
  };

  renderHome() {

    const { t } = this.props;
    return (
      <React.Fragment>
        <Row>

          <Col md={12} className={"home-main-tabs"}>
            <Col xs={12} md={12} lg={12} className="logout white-color pl-0 mb-1 trading">
              <div className="text-left text-md-left text-lg-left ">
                <span > {t("tradingHours")}</span>
                <span className="d-inline"> {t("trading_hours")}</span>
              </div>


            </Col>

            {this.state.categories.length > 0 && (
              <Animated
                animationIn="slideInLeft"
                animationInDuration={500}
                animationOutDuration={1000}
                isVisible={true}
              >
                <Tabs
                  defaultActiveKey={
                    this.state.categories[0].items.length > 0
                      ? this.state.categories[0].id
                      : this.state.categories[1].id
                  }
                  id="uncontrolled-tab-example"
                  className="table-responsive"
                >
                  {this.state.categories.map(
                    (category, index) =>
                      category.items.length > 0 && (
                        <Tab
                          key={category.id}
                          eventKey={category.id}
                          title={t(category.name)}
                          className="mt-4"
                        >
                          <Row>
                            {category.items.map((item, index) => {
                              let itemOpenOrderIndex = [];
                              this.state.openOrders.forEach((order, index) => {
                                if (
                                  parseInt(item.item.id) ===
                                  parseInt(order.item_id)
                                ) {
                                  itemOpenOrderIndex.push(index);
                                }
                              });

                              return (
                                <Item
                                  key={item.item.id}
                                  item={item.item}
                                  onPlaceOrder={this.handlePlaceOrder}
                                  showWatchList={this.state.isLoggedIn}
                                  isFavorite={
                                    this.state.isLoggedIn
                                      ? this.state.user.watchList.includes(
                                        item.item.id
                                      )
                                      : false
                                  }
                                  onWatchlistClicked={this.handleWatchlist}
                                  openOrders={this.state.openOrders}
                                  itemOpenOrderIndex={itemOpenOrderIndex}
                                  onOpenOrderClicked={this.handleOpenOrder}
                                />
                              );
                            })}
                          </Row>
                        </Tab>
                      )
                  )}
                </Tabs>
              </Animated>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-input"
          show={this.state.showPlaceOrder}
          onHide={this.handleClosePlaceOrder}
        >
          <Modal.Header closeButton>
            {`${this.state.placeOrderInfo.pos_type === 1 ? t("long") : t("short")
              } ${this.state.placeOrderInfo.item_name} ${t("at")} ${t(
                "price"
              )} ${(this.state.placeOrderInfo.price / 10).toFixed(1)}`}
          </Modal.Header>
          <Modal.Body>
            <PlaceOrderForm onOrderPlaced={this.handleOrderPlaced} />
          </Modal.Body>
        </Modal>

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-input"
          show={this.state.showOpenOrders}
          onHide={this.handleCloseOpenOrders}
          size="lg"
        >
          <Modal.Header closeButton>Open orders on queue</Modal.Header>
          <Modal.Body>
            <div className="invite-bg">
              <Table className="table">
                <thead className="text-uppercase">
                  <tr>
                    <th>{t("item-id")} </th>
                    <th>{t("item")}</th>
                    <th>{t("order")}</th>
                    <th>{t("qty")}</th>
                    <th>{t("order-price")} </th>
                    <th>{t("cancel-order")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.openOrderDetails.length === 0 ? (
                    <tr>
                      <td colSpan="6">{t("no-open-orders")}</td>
                    </tr>
                  ) : (
                    this.state.openOrderDetails.map((order, index) => (
                      <tr key={this.state.openOrders[order]._id}>
                        <td>{this.state.openOrders[order].item_disp_id}</td>
                        <td>{this.state.openOrders[order].item_name}</td>
                        <td>{this.state.openOrders[order].pos_type}</td>
                        <td>{this.state.openOrders[order].qty}</td>
                        <td>{this.state.openOrders[order].order_price}</td>
                        <td>
                          {this.state.openOrders[order].isCancelling ? (
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <Image
                              src={Cancel}
                              onClick={() => this.handleCancelOrder(order)}
                              className="mx-auto d-block"
                            />
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>

        <NavigationBar
          navBarText={this.state.navBarText}
          onMyAccountClick={this.handleMyAccountClick}
          onLogoutClick={this.handleLogoutClick}
          onMerchantClick={this.handleMerchantClick}
          onLogoClick={this.handleLogoClick}

        />
        <Container fluid className="mt-1">
          {this.state.currentPage === "home" ? (
            this.renderHome()
          ) : this.state.currentPage === "myaccount" ? (
            <Animated
              animationIn="fadeInRight"
              animationInDuration={500}
              isVisible={true}
            >
              <MyAccount
                accType={this.state.user.accType}
                portfolio={this.state.portfolio}
                netPos={this.state.netPos}
                openOrders={this.state.openOrders}
                transactions={this.state.transactionsDisplay}
                transfers={this.state.transfersDisplay}
                onCancelOrder={this.handleCancelOrder}
                onShare={this.handleShare}
                onSendToEosAcc={this.handleSendToEosAcc}
                onInvite={this.handleInvite}
                viewMoreTransactionBtn={this.state.viewMoreTransactionBtn}
                viewMoreTransfersBtn={this.state.viewMoreTransfersBtn}
                onViewMoreTransaction={this.handleViewMoreTransaction}
                onViewMoreTransfers={this.handleViewMoreTransfers}
                onLogoutClick={this.handleLogoutClick}
                onLogoutAllClick={this.handleLogoutAllClick}
                onChangePassword={this.handleChangePassword}
                onSetPin={this.handleSetPin}
              />
            </Animated>
          ) : (
            <Merchant />
          )}
        </Container>
      </React.Fragment>
    );
  }
}

const HomeWithLang = withTranslation()(Home);
export default HomeWithLang;


