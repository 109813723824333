import http from "./httpService";
import { apiUrl } from "../config.js";

const apiEndpoint = apiUrl;

function registerUrl() {
  return apiEndpoint + "users/";
}

function createAccountUrl() {
  return apiEndpoint + "create/";
}

function inviteUrl() {
  return apiEndpoint + "users/invite/";
}

function isInvitedEmailUrl(email) {
  return `${apiEndpoint}users/is_invited_email/${email}`;
}

function getReferrerUrl(email) {
  return `${apiEndpoint}users/referrer/${email}`;
}

function isValidJulAccNameUrl(julAccName) {
  return `${apiEndpoint}users/account/${julAccName}`;
}

function getJulAccNameUrl(email) {
  return `${apiEndpoint}users/jul_acc_name/${email}`;
}

function getCreateAccountUrl() {
  return `${apiEndpoint}users/acc_create`;
}

function getTransfersUrl() {
  return `${apiEndpoint}users/transfers`;
}

function getTransactionsUrl() {
  return `${apiEndpoint}users/transactions`;
}

function watchlistUrl() {
  return `${apiEndpoint}users/watchlist`;
}

function getNewsUrl(item_id) {
  return `${apiEndpoint}news/${item_id}`;
}

function statisticsUrl(item_id) {
  return `${apiEndpoint}statistics/${item_id}`;
}

function priceChartUrl(item_id) {
  return `${apiEndpoint}pricechart/${item_id}?type=day`;
}

function merchantDetailsUrl(country, category) {
  return `${apiEndpoint}merchantdetails/${country}/${category}`;
}

function buyPriceUrl() {
  return `${apiEndpoint}buyjoule/price`;
}

function getPostsUrl(itemId) {
  return `${apiEndpoint}forum/${itemId}`;
}

function addPostUrl() {
  return `${apiEndpoint}forum`;
}

function addVoteUrl() {
  return `${apiEndpoint}forum/vote`;
}

function addReportUrl() {
  return `${apiEndpoint}forum/report`;
}

function logoutUrl() {
  return `${apiEndpoint}users/logout`;
}

function beneficiaryUrl() {
  return `${apiEndpoint}beneficiary`;
}
function getBeneficiaryUrl(userAccName) {
  return `${apiEndpoint}beneficiary/${userAccName}`;
}

export function createAccount(account_name, public_key) {
  console.log("register post");
  return http.post(createAccountUrl(), {
    account_name,
    public_key,
  });
}

export function signUp(user) {
  console.log("register post");
  // generate the public key
  return http.post(registerUrl(), user);
}

export function getReferrer(email) {
  return http.get(getReferrerUrl(email));
}

export function isInvitedEmail(email) {
  return http.get(isInvitedEmailUrl(email));
}

export function isValidJulAccName(julAccName) {
  return http.get(isValidJulAccNameUrl(julAccName));
}

export function getJulAccName(email) {
  return http.get(getJulAccNameUrl(email));
}

export function createJulAccount() {
  return http.post(getCreateAccountUrl());
}

export function sendInvite(email, referrer) {
  return http.post(inviteUrl(), {
    email,
    referrer,
  });
}

export function getTransfers() {
  return http.get(getTransfersUrl());
}

export function getTransactions() {
  return http.get(getTransactionsUrl());
}

export function getWatchlist() {
  return http.get(watchlistUrl());
}

export function addToWatchlist(item_id) {
  return http.post(watchlistUrl(), {
    item_id,
  });
}

export function deleteFromWatchlist(item_id) {
  return http.delete(watchlistUrl(), {
    data: {
      item_id,
    },
  });
}
export function getNews(item_id) {
  return http.get(getNewsUrl(item_id));
}

export function getStatistics(item_id) {
  return http.get(statisticsUrl(item_id));
}

export function getPriceChart(item_id) {
  return http.get(priceChartUrl(item_id));
}

export function getMerchantDetails(country, category) {
  return http.get(merchantDetailsUrl(country, category));
}

export function getBuyPrice() {
  return http.get(buyPriceUrl());
}

export function getPosts(itemId) {
  return http.get(getPostsUrl(itemId));
}

export function addPost(data) {
  return http.post(addPostUrl(), data);
}

export function votePost(_id) {
  return http.post(addVoteUrl(), {
    _id,
  });
}
export function reportPost(_id) {
  return http.post(addReportUrl(), {
    _id,
  });
}

export function logoutAllSession() {
  return http.put(logoutUrl(), {});
}

export function addBeneficiary(benefAccName, userAccName) {
  return http.post(beneficiaryUrl(), { benefAccName, userAccName });
}

export function getBeneficiary(userAccName) {
  return http.get(getBeneficiaryUrl(userAccName));
}
