import {
  blockchain
} from "../config.js";
import {
  createClient
} from "@liquidapps/dapp-client";

const getClient = async () => {
  return await createClient({
    network: "kylin",
    httpEndpoint: blockchain.nodeosEndpoint,
    fetch: window.fetch.bind(window),
  });
};


export async function PlaceOrder(
  itemId,
  julAccName,
  price,
  pos_type,
  qty,
  key
) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "placeorder", {
        vaccount: julAccName,
        item_id: itemId,
        price: price,
        pos_type: pos_type,
        qty: qty,
      }
    );
    console.log(response);
    return ({
      success: true,
      msg: "order-success"
    })
  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "order-failed"
      })
    }
  }
}


export async function getMyPortfolio(julAccName) {
  // let userPortfolio = {};
  try {
    const service = await (await getClient()).service(
      "ipfs",
      blockchain.contract
    );

    const response = await service.get_vram_row(
      blockchain.contract,
      blockchain.contract,
      "portfoliov1",
      julAccName
    );

    return response.row;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

export async function getOrders(orderIds) {
  try {
    const service = await (await getClient()).service(
      "ipfs",
      blockchain.contract
    );

    let orders = orderIds.map((orderId) =>
      service.get_vram_row(
        blockchain.contract,
        blockchain.contract,
        "orders",
        orderId, {
          key_type: "number",
          key_size: 64,
        }
      )
    );
    const ordersResult = await Promise.all(orders);
    let ordersList = ordersResult.map((order) => order.row);
    return ordersList;
  } catch (ex) {
    console.error(ex);
    return [];
  }
}

export async function cancelOrder(julAccName, order_id, key) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "cancelorder", {
        vaccount: julAccName,
        order_id: order_id,
      }
    );
    console.log(response);
    return ({
      
      success: true,
      msg: "order-cancel-success"
    })
  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "order-cancel-fail"
      })
    }
  }
}

export async function share(julAccName, toJulAccName, amount, key) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "share", {
        vaccount: julAccName,
        to: toJulAccName,
        quantity: `${Number(amount).toFixed(4)} JUL`,
        memo: "",
      }
    );
    console.log(response);
    


    return ({
      success: true,
      msg: "amount-sharing-success"
      
    })
  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "amount-sharing-fail"
      })
    }
  }
}

export async function sendToEosAcc(
  julAccName,
  toEosAccName,
  amount,
  memo,
  key
) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );

    const params = {
      vaccount: julAccName,
      to: toEosAccName,
      quantity: `${Number(amount).toFixed(4)} JUL`,
      memo: memo,
    };
    console.log(params, params);
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "sendtoeosacc",
      params
    );
    console.log(response);
    return ({
      success: true,
      msg: "amount-payment-success"
    })

  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "amount-payment-fail"
      })
    }
  }
}


export async function eosJulClaim(julAccName, key) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "eosjulclaim", {
        vaccount: julAccName
      }
    );
    console.log(response);
    return ({
      success: true,
      msg: "eos-julclaim-success"
    })
  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "eos-julclaim-fail"
      })
    }
  }
}



export async function changeKey(julAccName, newKey, key) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "changekey", {
        vaccount: julAccName,
        pubkey: newKey
      }
    );
    console.log(response);
    return ({
      success: true,
      msg: "changekey-success"
    })
  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "changekey-fail"
      })
    }
  }
}


export async function setPubKey(julAccName, pubkey, key) {
  try {
    const service = await (await getClient()).service(
      "vaccounts",
      blockchain.contract
    );
    const response = await service.push_liquid_account_transaction(
      blockchain.contract,
      key,
      "setpubkey", {
        vaccount: julAccName,
        pubkey: pubkey
      }
    );
    console.log(response);
    return ({
      success: true,
      msg: "setpubkey-success"
    })
  } catch (e) {
    console.log(e);
    try {
      const errorJson = e.toString().substring(7);
      const errorObj = JSON.parse(errorJson);
      const errorMsg = errorObj.error.details[0].message;
      return ({
        success: false,
        msg: errorMsg.substring(32)
      })
    } catch (ex) {
      return ({
        success: false,
        msg: "pubkey-fail"
      })
    }
  }
}