import React, { useState, useEffect } from "react";
import Merchant_brand from "../assets/parknshop.jpg";
import Instagram from "../assets/instagram.png";
import Link from "../assets/link.png";
import Copy from "../assets/copy.png";
import Pdf from "../assets/pdf.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container, Image, Modal } from "react-bootstrap";
import MerchantDetail from "./merchant-details";
import MerchantSticker from "./merchant-sticker";
import MerchantStickerHK from "./merchant-sticker-hk";
import { withTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getMerchantDetails } from "../services/userService";
import Home from "./home";

function MerchantStickerModal(props) {
  const { onCustomClose, ...rest } = { ...props };
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-input"
    >
      <Modal.Header>
        <Row>
          <Col md={10} className="text-left">
            Merchant Sticker
          </Col>
          <Col md={2}>
            <Row>
              <Col>
                <a
                  href="/merchant-poster.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download"
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="2x"
                    color="#009688"
                  />
                </a>
              </Col>
              <Col>
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="2x"
                  color="#009688"
                  onClick={onCustomClose}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <MerchantSticker />
      </Modal.Body>
    </Modal>
  );
}

function MerchantStickerHKModal(props) {
  const { onCustomClose, ...rest } = { ...props };
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-input"
    >
      <Modal.Header>
        <Row>
          <Col md={10} className="text-left">
            Merchant Sticker HK
          </Col>
          <Col md={2}>
            <Row>
              <Col>
                <a
                  href="/merchant-poster-hk.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download"
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="2x"
                    color="#009688"
                  />
                </a>
              </Col>
              <Col>
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="2x"
                  color="#009688"
                  onClick={onCustomClose}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <MerchantStickerHK />
      </Modal.Body>
    </Modal>
  );
}
function Merchant(props) {
  const { t } = props;
  const [merchantDetailShow, setMerchantDetailShow] = React.useState(false);
  const [merchantDetailUrl, setMerchantDetailUrl] = React.useState("");
  const [merchantStickerShow, setMerchantStickerShow] = React.useState(false);

  const [merchantStickerHKShow, setMerchantStickerHKShow] = React.useState(
    false
  );

  const [countryFilter, setCountryFilter] = React.useState("global");
  const [categoryFilter, setCategoryFilter] = React.useState("all");
  const [merchantList, setMerchantList] = React.useState([]);
  function MerchantDetailModal(props) {
    const { onCustomClose, ...rest } = { ...props };

    return (
      <Modal
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-input"
      >
        <Modal.Header>
          <Row>
            <Col md={10}>Details & Instructions</Col>
            <Col md={2}>
              <Row>
                <Col>
                  <a
                    href={merchantDetailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="2x"
                      color="#009688"
                    />
                  </a>
                </Col>
                <Col>
                  <FontAwesomeIcon
                    icon={faWindowClose}
                    size="2x"
                    color="#009688"
                    onClick={onCustomClose}
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <MerchantDetail url={merchantDetailUrl} />
        </Modal.Body>
      </Modal>
    );
  }

  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data: merchants } = await getMerchantDetails(
          countryFilter,
          categoryFilter
        );
        setMerchantList(merchants);
        setFetched(true);
        console.log(merchants);
      } catch (ex) {
        console.log(ex.message);
      }
    }
    if (!fetched) {
      fetchData();
    }
  });
  function onCopy() {
    console.log("copied account name");
  }

  function handleFilterChange({ currentTarget: input }) {
    if (input.name === "country_region") {
      setCountryFilter(input.value);
    } else {
      setCategoryFilter(input.value);
    }
    setFetched(false);
  }

  function merchantInfo(merchant) {
    return (


      <Col md={4} className="merchant-tab1 px-0">

        <div className="merchant-tab">
          <h3 className="d-inline-block">#{("00" + merchant.id).slice(-3)}</h3>
          <h3 className="d-inline-block float-right" >{merchant.merchantId.country}</h3>
          <h3 className="mb-4">{merchant.merchantId.merchantName}</h3>

          <h5>
            Contact Us:{" "}
            {/* <a href={`mailto:${merchant.merchantId.email}`}> */}
            {merchant.contactInfo}
            {/* </a> */}
          </h5>

          <h5>
            Account: {merchant.merchantId.julAccName} {"   "}
            <CopyToClipboard
              text={merchant.merchantId.julAccName}
              onCopy={onCopy}
            >
              <button>
                <Image src={Copy} width="18" />
              </button>
            </CopyToClipboard>
          </h5>
          
          <button
            onClick={() => {
              setMerchantDetailUrl(merchant.details_instructions);
              setMerchantDetailShow(true);
              console.log("test")
            }}
            className="btn btn-light my-4 w-100"
          >
            <Image src={Link} width="30" className="pr-2" />
            Details & Instructions
          </button>
        </div>
        <div className="bottom-img text-center pl-4">
          <h6 className="text-center">Follow Us On </h6>
          <a href={merchant.web} target="_blank" rel="noopener noreferrer">
            <Image src={Instagram} width="20" /> {merchant.web}
          </a>
          <Image src={merchant.image} className="img-fluid" />
        </div>
      </Col>
    );
  }

  return (
    <React.Fragment>
      <MerchantDetailModal
        show={merchantDetailShow}
        onHide={() => setMerchantDetailShow(false)}
        onCustomClose={() => setMerchantDetailShow(false)}
      />
      <MerchantStickerModal
        show={merchantStickerShow}
        onHide={() => setMerchantStickerShow(false)}
        onCustomClose={() => setMerchantStickerShow(false)}
      />
      <MerchantStickerHKModal
        show={merchantStickerHKShow}
        onHide={() => setMerchantStickerHKShow(false)}
        onCustomClose={() => setMerchantStickerHKShow(false)}
      />
      <div className="merchant-main mt-2">
        <Container fluid>
          <Row className="d-flex justify-content-center mb-4">
            <Col>
              <button
                onClick={() => setMerchantStickerShow(true)}
                title={t("title_message1")}
                className="btn btn-light"
              >
                <Image src={Pdf} width="25" className="mr-2" />
                {t("merchant_sticker")}
              </button>
            </Col>
            <Col>
              <button
                onClick={() => setMerchantStickerHKShow(true)}
                className="btn btn-light"
                title={t("title_message2")}
              >
                <Image src={Pdf} width="25" className="mr-2" />
                {t("merchant_stickerhk")}
              </button>
            </Col>
          </Row>

          <Row>
            <Col md={11} className="mx-auto">
              <Row>
                <Col md={4}>
                  <select
                    name="country_region"
                    id="country_region"
                    className="form-control mb-2 mb-md-0"
                    value={countryFilter}
                    onChange={handleFilterChange}
                  >
                    <option value="global">{t("country_region")}</option>
                    <option value="global-online"> {t("global_online")}</option>
                    <option value="hongkong"> {t("hong_kong")}</option>
                    <option value="others"> {t("others")}</option>
                  </select>
                </Col>
                <Col md={4}>
                  <select
                    name="category"
                    id="category"
                    className="form-control mb-2 mb-md-0"
                    value={categoryFilter}
                    onChange={handleFilterChange}
                  >
                    <option value="all">{t("category")}</option>
                    <option value="grocery">{t("grocery")}</option>
                    <option value="restaurant">{t("restaurant")}</option>
                    <option value="services">{t("services")}</option>
                    <option value="local-creative">
                      {t("local_creative")}
                    </option>
                    <option value="lifestyle">{t("lifestyle")}</option>
                    <option value="others">{t("others")}</option>local-creative
                  </select>
                </Col>
                <Col md={4}>
                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0"
                    placeholder={t("search_keywords")}
                  ></input>
                </Col>
              </Row>

              <p className="text-center mt-4">{t("notice_msg_1")}</p>
              <p className="text-center">
                {t("notice_msg_2A")}
                <a href="mailto:info@jouleindex.com">info@jouleindex.com</a>
                {t("notice_msg_2B")}
              </p>

              <Row className="mt-5 mb-5">
                {merchantList.map((merchant) => merchantInfo(merchant)
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
const MerchantWithLang = withTranslation()(Merchant);

export default MerchantWithLang;
