import React, { Component } from "react";
import Chart from "react-apexcharts";

class Piecharts extends Component {
  constructor(props) {
    super(props);

    let labels = [];
    let series = [];
    Object.entries(this.props.data).forEach(([key, value]) => {
      labels.push(key);
      series.push(value);
    });
    this.state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        labels: labels,
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val, opts) {
        //     console.log(opts);
        //     return val + "%";
        // },
        // },

        plotOptions: {
          pie: {
            startAngle: 0,
            expandOnClick: true,
            offsetX: 25,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
          },
        },
      },
      series: series,
    };
  }
  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="pie"
        width="80%"
      />
    );
  }
}

export default Piecharts;
