export const apiUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL;

export const blockchain = {
    contract: process.env.REACT_APP_CONTRACT_NAME,
    nodeosEndpoint: process.env.REACT_APP_NODEOS_END_POINT
};

export const paypalAppId = {
    env: process.env.REACT_APP_PAYPAL_ENV,
    sandbox: process.env.REACT_APP_PAYPAL_SANDBOX,
    production: process.env.REACT_APP_PAYPAL_PRODUCTION
}
// {
//     "apiUrl": "http://192.168.1.227:3005/api/",
//     "imgBaseUrl": "https://api.jouleindex.com/images/",
//     "blockchain": {
//         "contract": "dappdevgopal",
//         "nodeosEndpoint": "https://kylin-dsp-2.liquidapps.io"
//     }
// }