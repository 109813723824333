import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { SizeMe } from "react-sizeme";
import { withTranslation } from "react-i18next";

class MerchantDetails extends Component {
  state = { numPages: null, pageNumber: 1, nextBtn: false, prevBtn: false };
  onDocumentLoadSuccess = ({ numPages }) => {
    let nextBtn = false;
    let prevBtn = false;
    if (numPages > 1) {
      nextBtn = true;
    }
    this.setState({ numPages, nextBtn, prevBtn });
  };

  goToPrevPage = () => {
    let { pageNumber, prevBtn, nextBtn, numPages } = { ...this.state };
    if (pageNumber > 1) {
      pageNumber -= 1;
    }
    if (pageNumber === 1) {
      prevBtn = false;
    }
    if (pageNumber < numPages) {
      nextBtn = true;
    }
    this.setState({ pageNumber, prevBtn, nextBtn });
  };

  goToNextPage = () => {
    let { pageNumber, numPages, prevBtn, nextBtn } = { ...this.state };
    if (pageNumber < numPages) {
      pageNumber += 1;
    }
    if (pageNumber === numPages) {
      nextBtn = false;
    }
    if (numPages > 1 && pageNumber > 1) {
      prevBtn = true;
    }
    this.setState({ pageNumber, nextBtn, prevBtn });
  };

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div>
        <SizeMe
          monitorHeight
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div>
              <Document
                file={this.props.url}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} width={size.width} />
              </Document>
            </div>
          )}
        />
        <Container>
          <Row className="mt-2">
            <Col md={8}>
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </Col>
            <Col md={4}>
              {this.state.prevBtn && (
                <Button variant="success" onClick={this.goToPrevPage}>
                  {"<<"}
                </Button>
              )}

              {this.state.nextBtn && (
                <Button
                  className="ml-2"
                  variant="success"
                  onClick={this.goToNextPage}
                >
                  {">>"}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const MerchantDetailsWithLang = withTranslation()(MerchantDetails);
export default MerchantDetailsWithLang;
