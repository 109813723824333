import React, { Component } from "react";
import { Container, Col, Row, Image, Dropdown } from "react-bootstrap";
import Joulelogo from "../assets/joule-logo.png";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
class NavigationBarSignUp extends Component {
  render() {
    const { t } = this.props;
    return (
      <Container fluid className="navbar-menu py-1 py-lg-2">
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <Col xs={8} md={10} className="logo my-auto">
                <h3 className="text-uppercase mb-0 my-auto">
                  <Link to="/" className="text-lowercase ">
                    <Image
                      onClick={this.props.onLogoClick}
                      src={Joulelogo}
                      alt="Joule Global Popularity Index"
                    />
                  </Link>
                  {"    "}
                  {t("appName")}
                  {"  "}
                </h3>
              </Col>
              <Col xs={3} md={2} className="logout">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    {this.props.i18n.language === "zh-CN"
                      ? "简体中文"
                      : this.props.i18n.language === "zh-TW"
                      ? "繁體中文"
                      : "English"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.props.i18n.language !== "en" && (
                      <Dropdown.Item
                        onClick={() => this.props.i18n.changeLanguage("en")}
                      >
                        English
                      </Dropdown.Item>
                    )}
                    {this.props.i18n.language !== "zh-CN" && (
                      <Dropdown.Item
                        onClick={() => this.props.i18n.changeLanguage("zh-CN")}
                      >
                        简体中文
                      </Dropdown.Item>
                    )}
                    {this.props.i18n.language !== "zh-TW" && (
                      <Dropdown.Item
                        onClick={() => this.props.i18n.changeLanguage("zh-TW")}
                      >
                        繁體中文
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const NavigationBarSignUpWithLang = withTranslation()(NavigationBarSignUp);
export default NavigationBarSignUpWithLang;
