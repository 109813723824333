import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { Row, Col, Container } from "react-bootstrap";
import "./common/common.css";
import { getKey, getPublicKey, updateKey } from "../services/authService";
import { toast } from "react-toastify";
import MyModal from "./common/myModal";
import { getCurrentUser } from "../services/authService";
import { withTranslation } from "react-i18next";

import { changeKey } from "../services/dappClientService";

class ChangePwForm extends Form {
  state = {
    data: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    errors: {},
    successDialogOpen: false,
    loading: false,
    selected: [],
  };

  schema = {
    currentPassword: Joi.string()
      .required()
      .min(6)
      .label(this.props.t("currentPassword")),
    newPassword: Joi.string()
      .required()
      .min(6)
      .label(this.props.t("newPassword")),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref("newPassword"))
      .label(this.props.t("confirm-password")),
  };

  doHandleChange = (input, errorMessage) => {
    if (input.name === "confirmPassword") {
      const newPassword = this.schema["newPassword"];
      const confirmPassword = this.schema["confirmPassword"];
      const { error } = Joi.validate(
        {
          newPassword: this.state.data.newPassword,
          confirmPassword: input.value,
        },
        { newPassword, confirmPassword }
      );
      if (error) {
        return this.props.t("password-confirm-password");
      } else {
        return null;
      }
    }
    return errorMessage;
  };

  doSubmit = async () => {
    // Call the server

    try {
      const newKey = await getKey(
        this.props.julAccName,
        this.state.data.newPassword
      );
      const newPubKey = await getPublicKey(newKey);
      this.setState({ loading: true });
      const result = await changeKey(
        this.props.julAccName,
        newPubKey,
        getCurrentUser().key
      );
      await updateKey(this.props.julAccName, this.state.data.newPassword);
      this.props.onChangePassword();
      this.setState({ loading: false });
      // toast.success("Password Changed");

      const successDialogOpen = true;
      this.setState({ successDialogOpen });

      console.log(result);
    } catch (ex) {
      if (ex.response) {
        toast.error(ex.response.data);
        console.log(ex.response);
      }
    }
    this.setState({ loading: false });
    console.log("Set new password");
  };

  doFocusOut = async (input) => {
    let errorMessage = null;
    // console.log(input);
    switch (input.name) {
      case "currentPassword":
        // check the entered password is correct

        break;
      default:
    }

    return errorMessage;
  };

  handleSuccessDialogClose = () => {
    this.setState({ successDialogOpen: false });
    this.props.onHide();
  };

  componentDidMount() {}

  handleSubmitOveride = async (e) => {
    e.preventDefault();
    // depending upon the account type remove the elements this is done only for the validations
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    console.log(this.props.julAccName, this.state.data.currentPassword);
    console.log("Submitted");
    const key = await getKey(
      this.props.julAccName,
      this.state.data.currentPassword
    );
    // console.log("entered key", key);

    const user = getCurrentUser();
    if (user) {
      console.log("actual key", user.key);
      if (user.key === key) {
        // password correct
      } else {
        // set the error in
        let errors = {};
        errors["currentPassword"] = "Incorrect password";
        this.setState({ errors: errors });
        return;
      }
    } else {
      console.error("User is not logged in");
      return;
    }

    this.doSubmit();
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MyModal
          heading={t("pass_change_success")}
          body={t("pass_change_successfully")}
          show={this.state.successDialogOpen}
          onHide={this.handleSuccessDialogClose}
        />
        <Container fluid>
          <Row>
            <Col>
              <div className="sign-in p-3 p-lg-4">
                <form onSubmit={this.handleSubmitOveride}>
                  {this.renderInput(
                    "currentPassword",
                    t("old_password"),
                    "password"
                  )}

                  {this.renderInput(
                    "newPassword",
                    t("newPassword"),
                    "password"
                  )}
                  <p className="password-warn mt-3 d-block">
                    {t("password-warning")}
                  </p>

                  {this.renderInput(
                    "confirmPassword",
                    t("confirm_password"),
                    "password"
                  )}

                  {this.renderButton(t("change_password"))}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const ChangePwFormWithLang = withTranslation()(ChangePwForm);
export default ChangePwFormWithLang;
