import React from "react";
import "./common/common.css";
import { Row, Col, InputGroup } from "react-bootstrap";
import PaypalBuyJoule from "./paypal";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { getBuyPrice } from "../services/userService";

class JouleForm extends React.Component {
  state = {
    selectedOption: "30", //27.5
    priceList: [],
  };

  priceList = [
    {
      amount: 30, //27.5
      quantity: "500 J",
    },
    {
      amount: 114, //108
      quantity: "2000 J",
    },
    {
      amount: 570, //523
      quantity: "10000 J",
    },
  ];

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };

  onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
    console.log("The payment was succeeded!", payment);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    toast.success(this.props.t("payment_success_msg"));
  };

  onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    toast.error(this.props.t("payment_cancel_msg"));
  };

  async componentDidMount() {
    try {
      const { data } = await getBuyPrice();
      let selectedOption = data[0].price.toString();
      this.setState({ selectedOption, priceList: data });
      console.log(data);
    } catch (ex) {
      console.error(ex);
    }
  }

  // onError = (err) => {
  //   // The main Paypal's script cannot be loaded or somethings block the loading of that script!
  //   console.log("Error!", err);
  //   // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
  //   // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  //   toast.error("Unable to load Buy joule");
  // };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col md={6} className="mx-auto buy-joule">
            <h3>{t("joule-transferred-to")}</h3>
            <h5>{this.props.julAccName}</h5>
            <div className="joule-amount mt-4 py-3 px-5 mb-3">
              <form>
                <h4>{t("select-amount")}</h4>
                {this.state.priceList.map((option, index) => (
                  <InputGroup>
                    <InputGroup.Radio
                      value={option.price.toString()}
                      checked={
                        this.state.selectedOption === option.price.toString()
                      }
                      onChange={this.handleOptionChange}
                    />
                    {`${option.jul_quantity} J - US$ ${option.price}`}
                  </InputGroup>
                ))}
              </form>
            </div>
            <PaypalBuyJoule
              amount={parseFloat(this.state.selectedOption)}
              julAccName={this.props.julAccName}
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const JouleFormWithLang = withTranslation()(JouleForm);
export default JouleFormWithLang;
