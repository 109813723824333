import React, { Component } from "react";
import Chart from "react-apexcharts";
import { getPriceChart } from "../services/userService";

export default class CandlestickChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        title: {
          text: props.title,
          align: "left",
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
              customIcons: [],
            },
            autoSelected: "zoom",
          },
          zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
        },
      },

      series: [
        {
          data: [],
        },
      ],
    };
  }

  async componentDidMount() {
    try {
      const { data } = await getPriceChart(this.props.item_id);
      let displayChart = [];
      if (data.length > 0) {
        let currentDate = new Date(data[0].ts);
        let endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        while (currentDate < endDate) {
          let addTs = new Date(currentDate);
          let candle = { ts: addTs.toISOString() };
          currentDate.setDate(currentDate.getDate() + 1);
          displayChart.push(candle);
        }

        for (let i = 0; i < displayChart.length; i++) {
          // if(displayChart)
          let display = data.find(
            (element) => element.ts === displayChart[i].ts
          );
          if (display) {
            displayChart[i].do = display.do / 10;
            displayChart[i].dh = display.dh / 10;
            displayChart[i].dl = display.dl / 10;
            displayChart[i].dc = display.dc / 10;
          } else {
            if (i !== 0) {
              displayChart[i].do = displayChart[i - 1].dc;
              displayChart[i].dh = displayChart[i - 1].dc;
              displayChart[i].dl = displayChart[i - 1].dc;
              displayChart[i].dc = displayChart[i - 1].dc;
            } else {
              console.log("unexpected error chart");
            }
          }
        }
      } else {
        // create a record of 50 for the last 10 days
      }

      let chartData = displayChart.map((candle) => {
        return [candle.ts, [candle.do, candle.dh, candle.dl, candle.dc]];
      });

      this.setState({
        series: [{ data: chartData }],
      });
    } catch (ex) {
      console.log(ex.message);
    }
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="candlestick"
        width="100%"
        height="auto"
      />
    );
  }
}
