import React, { Component } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import Joulelogo from "../assets/joule-logo.png";
import Merchantlogo from "../assets/merchant-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
class NavigationBar extends Component {
  render() {
    const { t } = this.props;
    return (
      <Container fluid className="navbar-menu py-1 py-lg-2">
        <Row>
          <Col xs={12} md={12}>
            <Row>
              {/* <Col xs={5} md={10} className="logo my-auto"> */}
              {/* <Col
                xs={4}
                md={4}
                lg={9}
                className="logout d-lg-inline-block d-md-none d-sm-none d-none"
              >
                <h3 className="text-uppercase mb-0 my-auto">
                  <Image
                    onClick={this.props.onLogoClick}
                    src={Joulelogo}
                    alt="Joule Global Popularity Index"
                  />
                  {"    "}
                  {t("appName")}
                  {"  "}
                  <span className="d-lg-inline-block d-md-none d-sm-none d-none">
                    {t("appTag")}
                  </span>
                </h3>
              </Col> */}
              <Col xs={4} md={4} lg={4} className="logout order-1 order-lg-1">

                <ul className="pl-0 float-left mb-0">
                  <li onClick={this.props.onLogoClick}>
                    <Image
                      src={Joulelogo}
                      alt="Joule Global Popularity Index"
                      width="30"
                    />
                    <span className="d-block"> {t("appName")}</span>

                  </li>
                </ul>

              </Col>
              {/* <Col xs={12} md={12} lg={3} className="logout my-auto order-4 order-lg-2">
                <div className="text-left text-md-center text-lg-left ">
                  <span > {t("tradingHours")}</span>
                  <span className="d-inline d-lg-block"> {t("trading_hours")}</span>
                </div>


              </Col> */}

              <Col xs={4} md={4} lg={4} className="logout order-2 order-lg-3">
                <ul className="pl-0 text-center mb-0">
                  <li onClick={this.props.onMerchantClick}>
                    <Image src={Merchantlogo} width="48" />
                    <span className="d-block">{t("merchants")}</span>
                  </li>
                </ul>
              </Col>
              <Col xs={4} md={4} lg={4} className="logout order-3 order-lg-4">
                <ul className="pl-0 float-right mb-0">
                  <li onClick={this.props.onMyAccountClick}>
                    <FontAwesomeIcon icon={faUserAlt} />
                    <span className="d-block">{this.props.navBarText}</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >
    );
  }
}

const NavigationBarWithLang = withTranslation()(NavigationBar);
export default NavigationBarWithLang;
