import React from "react";

import { Button, Accordion, Card, Modal, Row, Col } from "react-bootstrap";
import UserGuide from "./userGuide";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
function UserGuideModal(props) {
  const { onCustomClose, ...rest } = { ...props };
  const { t } = useTranslation();
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-input"
    >
      <Modal.Header>
        <Row>
          <Col md={10}>{t("user-guide")}</Col>
          <Col md={2}>
            <Row>
              <Col>
                <a
                  href={`/${t("user-guide-file")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="2x"
                    color="#009688"
                  />
                </a>
              </Col>
              <Col>
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="2x"
                  color="#009688"
                  onClick={onCustomClose}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <UserGuide />
      </Modal.Body>
    </Modal>
  );
}

function Faq() {
  const [userGuideShow, setUserGuideShow] = React.useState(false);
  const { t } = useTranslation();
  let faqList = [];
  for (let i = 0; i < 18; i++) {
    faqList.push({ q: `faq_q${i + 1}`, a: `faq_a${i + 1}` });
  }

  return (
    <div className="faq">
      <UserGuideModal
        show={userGuideShow}
        onHide={() => setUserGuideShow(false)}
        onCustomClose={() => setUserGuideShow(false)}
      />
      <Button variant="success" onClick={() => setUserGuideShow(true)}>
        {t("user-guide")}
      </Button>
      <Accordion>
        {faqList.map((faq) => (
          <React.Fragment key={faq.q}>
            <Accordion.Toggle
              style={{ width: "100%", borderTop: "1px solid #003f39" }}
              as={Card.Header}
              variant="link"
              eventKey={faq.q}
              key={faq.q}
            >
              <Row>
                <Col
                  xs={10}
                  md={10}
                  style={{ fontSize: "14px", color: "#009688" }}
                >
                  {t(faq.q)}
                </Col>
                <Col xs={2} md={2}>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    size="1x"
                    color="#009688"
                    className="text-right"
                  />
                </Col>
              </Row>
            </Accordion.Toggle>

            <Accordion.Collapse as={Row} eventKey={faq.q} key={faq.a}>
              <p
                style={{ paddingLeft: "15px", fontSize: "12px", color: "#ccc" }}
              >
                {t(faq.a)}
              </p>
            </Accordion.Collapse>
          </React.Fragment>
        ))}
      </Accordion>
    </div>
  );
}

export default Faq;
